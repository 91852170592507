export interface HeaderLink {
    type: 'text' | 'image'
    name: string
    to: string
    color: string,
    hoverColor: string,
    fontWeight: number,
    image?: string,
    target?: string
}

export const headerLinks: HeaderLink[] = [
    {
        type: 'text',
        name: 'Cloud',
        color: "#41A0A6",
        hoverColor: "#41A0A6BA",
        fontWeight: 600,
        to: '/#cloud'
    },
    {
        type: 'text',
        name: 'OnPrem',
        color: "#E43A44",
        hoverColor: "#E43A44C9",
        fontWeight: 600,
        to: '/#on-premise'
    },
    {
        type: 'text',
        name: 'Compare',
        color: "#000000",
        hoverColor: "#8b8b8b",
        fontWeight: 400,
        to: '/#compare'
    },
    {
        type: 'text',
        name: 'Account',
        color: "#000000",
        hoverColor: "#8b8b8b",
        fontWeight: 400,
        to: '/account'
    },
    {
        type: 'text',
        name: 'Support',
        color: "#000000",
        hoverColor: "#8b8b8b",
        fontWeight: 400,
        to: '/support',
        target: '_blank'
    },
]
