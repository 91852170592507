import styles from "./index.module.scss";
import {Box, Image, Stack, Text} from "@chakra-ui/react";
import BowDownBlue from "../../assets/bow-down-blue.svg";
import BowUpRed from "../../assets/bow-up-red.svg";
import BowUpBlue from "../../assets/bow-up-blue.svg";
import React from "react";

export function ProcessStep({title, description, isCloud = false, reverse = false}) {
    return (
        <Stack
            direction={reverse ? "column-reverse" : "column"}
            spacing={8}
            alignItems={"center"}
            position={"relative"}
            m={0}
            marginInlineStart={"0 !important"}
            marginInlineEnd={"0 !important"}
            marginTop={reverse ? "100px !important" : "0 !important"}
            marginBottom={reverse ? "0 !important" : "100px !important"}
        >
            <Box
                textAlign={"left"}
                position={"absolute"}
                top={reverse ? "80%" : "-150%"}
            >
                <Text
                    pb={2}
                    fontWeight={700}
                    fontSize={"2em"}
                >
                    {title}
                </Text>
                <Text
                    pt={2}
                    fontWeight={700}
                    borderTop={`3px solid ${reverse ? "var(--primary-font-color)" : (isCloud ? "var(--primary-cloud-color)" : "var(--primary-onprem-color)")}`}
                >
                    {description}
                </Text>
            </Box>
            <Image
                src={reverse ? BowDownBlue : (isCloud ? BowUpBlue : BowUpRed) }
                role={"presentation"}
                alt={""}
            />
        </Stack>
    )
}