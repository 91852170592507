import styles from "./index.module.scss";
import CustomFormControl, {CustomFormControlSelect} from "../CustomFormControl";
import {HStack} from "@chakra-ui/react";
import {countries} from "countries-list";
import React from "react";

export function CompanyDetailsForm({watch, errors, register}){
    return <>
        <CustomFormControl
            fieldKey={"company.name"}
            label={"Company Name"}
            errors={errors}
            register={register}
            required={true}
            autoComplete="organization"
        />
        <CustomFormControl
            fieldKey={"company.number"}
            label={"Company Number"}
            errors={errors}
            register={register}
            required={true}
            mt={4}
        />
        <CustomFormControl
            fieldKey={"company.street"}
            label={"Street Address"}
            errors={errors}
            register={register}
            autoComplete="address-line1"
            required={true}
            mt={4}
        />

        <CustomFormControl
            fieldKey={"company.street2"}
            label={"Street Address 2"}
            errors={errors}
            register={register}
            autoComplete="address-line2"
            required={false}
            mt={4}
        />

        <HStack mt={4}>
            <CustomFormControl
                fieldKey={"company.zip"}
                label={"ZIP Code"}
                errors={errors}
                register={register}
                autoComplete="postal-code"
                required={true}
                controlProps={{
                    maxW: "35%"
                }}
            />

            <CustomFormControl
                fieldKey={"company.city"}
                label={"City"}
                errors={errors}
                register={register}
                autoComplete="home city"
                required={true}
            />
        </HStack>

        {watch("company.country") === "US" && <CustomFormControl
            fieldKey={"company.state"}
            label={"State"}
            errors={errors}
            register={register}
            autoComplete="address-level1"
            required={true}
            mt={4}
        />}

        <CustomFormControlSelect
            options={Object.entries(countries).map(([key, value]) => ({value: key, label: value.name}))}
            fieldKey={"company.country"}
            label={"Select country"}
            errors={errors}
            register={register}
            autoComplete="country"
            required={true}
            mt={4}
        />
    </>
}