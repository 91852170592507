import styles from './index.module.scss';
import {AppSignedIn} from "../../layouts/AppSignedIn";
import React, {useEffect, useState} from "react";
import {UserService} from "../../services/user";
import {CopyToClipboardField} from "../../modules/CopyToClipboardField";
import {
    Box,
    Button,
    Card,
    CardBody,
    Grid,
    HStack,
    Skeleton,
    Text,
    VStack
} from "@chakra-ui/react";
import {Pagination} from '@mantine/core';
import {SimpleServer, getCommand, serverStyle} from "../../modules/SimpleServer";
import {Server} from "../../modules/Server";
import {ProxySettingsButton} from "../../modules/ProxySettingsButton";
import {EntitlementsCardBanner} from "../../modules/EntitlementsCardBanner";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import {PurchaseMoreButton} from "../../modules/PurchaseMoreButton";

export default function Account() {
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [user, setUser] = useState({} as any)
    const [targetOS, setTargetOS] = useState("rhel8")

    const [serverState, setServerState] = useState<any>({})
    const [activePage, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const placeholderCount = 1
    const pageSize = 10

    // The first page has a placeholder, so we can only display 7 servers on the first page.
    // All other pages can display 8 servers
    const amountToGrab = activePage === 1 ? pageSize - placeholderCount : pageSize

    async function getServerState() {
        let body = {
            amount: amountToGrab,
            offset: (activePage - 1) * pageSize,
            sort: "time-a-z"
        }

        console.log(body)

        let response = await fetch("/api/servers/state", {
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(body),
            method: "POST"
        }).then(r => r.json())

        // The first page has a placeholder, so we need to add 1 to the total
        let totalToShow = response.total + placeholderCount
        setTotalPages(Math.floor(totalToShow / pageSize))
        setServerState(response)
        setShowSkeleton(false)
    }

    useEffect(() => {
        UserService.getMyInfo().then(setUser)

        getServerState().then()
        const interval = setInterval(getServerState, 3000);

        return () => clearInterval(interval);
    }, [activePage])

    return (
        <AppSignedIn user={user}>
            <HStack justifyContent={"space-between"}>
                <Text as={"b"} mb={"10px"} fontSize='2xl'>
                    {`Hi${user?.firstName ? " " : ""}${user?.firstName || ""}, welcome back`}
                </Text>
                <ProxySettingsButton user={user}/>
            </HStack>
            <Card className={"customCard"}>
                <CardBody p={{
                    base: 2,
                    lg: 5
                }}>
                    <EntitlementsCardBanner company={user?.company}/>
                    <Grid
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            lg: 'repeat(2, 1fr)'
                        }}
                        templateRows={{
                            base: 'repeat(10, 1fr)',
                            lg: 'repeat(5, 1fr)'
                        }}
                        gridAutoFlow={"column"}
                        marginY={4}
                        gap={4}
                    >
                        {activePage === 1 &&
                            <>
                                {user?.company?.availableLicenses > 0 && <>
                                    <SimpleServer targetOS={targetOS} setTargetOS={setTargetOS} title={"Prepare"}
                                                  className={"server"}>
                                        <Box>
                                            <Text as={"b"} fontSize={serverStyle.fontSize}>Copy/paste in terminal to
                                                prepare:</Text>
                                        </Box>
                                        <Box flexGrow={0.5}>
                                            <CopyToClipboardField
                                                text={!targetOS ? "Select a target OS first" : getCommand(user?.company, "audit", targetOS)}></CopyToClipboardField>
                                        </Box>
                                    </SimpleServer>
                                </>}
                                {user?.company?.availableLicenses === 0 && <>
                                    <SimpleServer hideTargetOs={true} title={"No available entitlements"}
                                                  className={"server"}>
                                        <Box>
                                            <Text as={"b"} fontSize={serverStyle.fontSize}>Please purchase more to start upgrading</Text>
                                        </Box>
                                        <Box>
                                            <PurchaseMoreButton />
                                        </Box>
                                    </SimpleServer>
                                </>}
                            </>
                        }
                        {!showSkeleton && serverState?.servers?.map((server, index) => {
                            return <Server
                                key={server.server.id}
                                user={user}
                                serverState={server}
                            />
                        })}
                        {showSkeleton && Array.from({length: amountToGrab}).map((_, i) => (
                            <Skeleton borderRadius={"9px"} key={i}>
                                <SimpleServer></SimpleServer>
                            </Skeleton>
                        ))}

                    </Grid>
                    <VStack justifyContent={"center"}>
                        <Pagination onChange={setPage} total={totalPages} withEdges/>
                    </VStack>
                </CardBody>
            </Card>
        </AppSignedIn>
    );
}
