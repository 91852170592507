import './index.scss';
import {useEffect} from "react";

export default function Support() {
    useEffect(() => {
        window.location.href = "https://project78.tawk.help/";
    }, []);

    return <></>;
}
