import styles from "./index.module.scss";
import { Link } from 'react-router-dom';
import {headerLinks, HeaderLink} from "../HeaderLinks";
import React from "react";
import {
    Box, CloseButton,
    Drawer,
    DrawerContent,
    Image, Stack,
    useDisclosure
} from "@chakra-ui/react";
import {LanguageSelector} from "../../modules/LanguageSelector";

interface LandingProps {
    children: any,
    disableShadow?: boolean,
    extendedHeader?: any,
    footer?: any,
}

function NavItem({ headerLinkConfig, onClick = () => {} }: { headerLinkConfig: HeaderLink, onClick?: any }){
    return (
        <Link to={headerLinkConfig.to} onClick={onClick} className={styles.navItem} target={headerLinkConfig.target} style={{
            color: headerLinkConfig.color,
            fontWeight: headerLinkConfig.fontWeight,
            "--hover-color": headerLinkConfig.hoverColor,
        } as any}>
            {headerLinkConfig.type === "text" && headerLinkConfig.name}
            {headerLinkConfig.type === "image" && <Image
                src={headerLinkConfig.image}
                alt={headerLinkConfig.name}
                className={styles.headerImage}
            />}
        </Link>
    )
}

export function Landing({children, disableShadow, extendedHeader, footer}: LandingProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return <>
        <header className={`${extendedHeader ? styles.extendedHeader : ""}`}>
            <nav className={`${styles.navigation} ${disableShadow ? styles.disableShadow : ""}`}>
                <a href={"/"} className={styles.logoContainer}>
                    <div className={styles.mainLogo} />
                    <div className={styles.subLogo} />
                </a>
                <div id="nav-menu" className={`${styles.menu}`}>
                    {headerLinks.map(link => <NavItem key={link.name} headerLinkConfig={link} />)}
                    <a href="#" className={styles.globe}>
                        <LanguageSelector/>
                    </a>
                </div>
                <Drawer
                    autoFocus={false}
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full">
                    <DrawerContent>
                        <Box
                            p={4}
                            w={{ base: 'full', md: 60 }}
                            pos="fixed"
                            h="full"
                        >
                            <CloseButton float={"right"} size={"lg"} display={"flex"} onClick={onClose} />
                            <Stack style={{clear: "both"}} p={8} spacing={8}>
                                {headerLinks.map(link => <NavItem key={link.name} headerLinkConfig={link} onClick={() => {
                                    if(link.to.includes("#")){
                                        onClose()
                                    }
                                }} />)}
                                <a href="#" className={styles.globe}>
                                    <LanguageSelector/>
                                </a>
                            </Stack>
                        </Box>
                    </DrawerContent>
                </Drawer>
                <div className={styles.hamburger} onClick={onOpen}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </nav>
        </header>
        {extendedHeader}
        <main className={styles.landingMain}>
            {children}
        </main>
        {footer}
    </>
}