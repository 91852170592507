import './index.scss';
import {App} from "../../layouts/App";
import {
    Container,
    Text,
    Stack,
    FormControl,
    Input,
    Button,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    HStack,
    Box,
    Checkbox,
    FormLabel,
    Link,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader, ModalCloseButton, ModalBody,
} from '@chakra-ui/react';
import {
    ViewIcon,
    ViewOffIcon
} from '@chakra-ui/icons';
import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {UserService} from "../../services/user";
import {useForm} from "react-hook-form";
import CustomFormControl from "../../modules/CustomFormControl";


export default function AccountSignUp() {
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit, watch} = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // get email from query
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');

    console.log(token, email)
    if(!token || !email) {
        window.location.href = "/account/forgot-password"
    }

    const handlePasswordVisibility = _ => setShowPassword(!showPassword);
    const onSubmit = async values => {
        try {
            await UserService.resetPassword(values)
            window.location.href = "/account/sign-in"
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }

        setShowPassword(false);
    }

    return (
        <App>
            <Container style={{paddingTop: "3%"}}>
                <Text as={"b"} fontSize='2xl'>Reset Password</Text>
                <form onSubmit={handleSubmit(onSubmit)} style={{padding: "15%"}}>
                    <input
                        type="hidden"
                        {...register(`token`)}
                        defaultValue={token}
                    />
                    <Stack spacing={4}>
                        <Stack spacing={6}>
                            <CustomFormControl
                                fieldKey={"email"}
                                label={"Email"}
                                errors={errors}
                                register={register}
                                required={true}
                                type={"email"}
                                defaultValue={email}
                                isReadOnly={true}
                            />
                            <FormControl isInvalid={errors.confirmPassword as unknown as boolean} variant={"floating"}>
                                <HStack>
                                    <Box>
                                        <InputGroup>
                                            <Input
                                                {...register("password", {required: true})}
                                                type={showPassword ? 'text' : 'password'}
                                                height={"3rem"}
                                                autoFocus={true}
                                            />
                                            <FormLabel>Password</FormLabel>
                                            <InputRightElement width="3rem" paddingTop={"0.5rem"}>
                                                <Button
                                                    backgroundColor={"transparent"}
                                                    size='sm'
                                                    tabIndex={-1}
                                                    onClick={handlePasswordVisibility}
                                                >
                                                    {!showPassword ? <ViewOffIcon/> : <ViewIcon/>}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                    </Box>
                                    <Box>
                                        <InputGroup>
                                            <Input
                                                {...register("confirmPassword", {
                                                    required: true,
                                                    validate: (val: string) => {
                                                        if (watch('password') !== val) {
                                                            return "Passwords do no match";
                                                        }
                                                    },
                                                })}
                                                type={showPassword ? 'text' : 'password'}
                                                height={"3rem"}
                                            />
                                            <FormLabel>Confirm</FormLabel>
                                            <InputRightElement width="3rem" paddingTop={"0.5rem"}>
                                                <Button
                                                    backgroundColor={"transparent"}
                                                    tabIndex={-1}
                                                    size='sm'
                                                    onClick={handlePasswordVisibility}
                                                >
                                                    {!showPassword ? <ViewOffIcon/> : <ViewIcon/>}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                    </Box>
                                </HStack>
                                <FormErrorMessage>{errors.confirmPassword?.message as unknown as string}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                        <Stack spacing={6}>
                            <Button
                                isLoading={isSubmitting}
                                type="submit"
                                width="full"
                                mt={4}
                                colorScheme={"black"}
                                backgroundColor={"black"}
                                height={"3rem"}
                            >
                                Reset password
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Container>
        </App>
    );
}
