import React from "react";
import {Landing} from "../Landing";

interface AppProps {
    children: any,
}

export function App({children}: AppProps) {
    return <>
        <Landing disableShadow={true}>
            {children}
        </Landing>
    </>
}