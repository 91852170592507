import { Navigate, useLocation, Outlet } from "react-router-dom";
import { CookieUtil } from "../../utils/cookie"
import { DateUtil } from "../../utils/date"
import React, {useEffect, useState} from "react";
import {UserService} from "../../services/user";

export default function RequireAuth(){
    let location = useLocation();
    let cookie = CookieUtil.getCookie("expires");
    let expiresCookieDate = new Date(parseInt(cookie));

    if(!DateUtil.isValidDate(expiresCookieDate) || expiresCookieDate < new Date()){
        // Save location so when user is logged in, they come back the previous location
        return <Navigate to="/account/sign-in" state={{ from: location }} />
    }

    return <Outlet />
}