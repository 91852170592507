import styles from "./index.module.scss";
import FullStar from "../../assets/full-star.svg";
import FullStarRed from "../../assets/full-star-red.svg";
import EmptyStar from "../../assets/empty-star.svg";
import EmptyStarRed from "../../assets/empty-star-red.svg";

export function Review({fullName, jobTitle, stars = 5, useRedStar = false, children}) {
    return <>
        <div className={styles.review}>
            <div className={styles.stars}>
                {Array.from({length: stars}, (_, i) => <img key={i} src={useRedStar ? FullStarRed : FullStar} alt={"Full Star"}/>)}
                {Array.from({length: 5 - stars}, (_, i) => <img key={i} src={useRedStar ? EmptyStarRed : EmptyStar} alt={"Empty Star"}/>)}
            </div>
            <div className={styles.text}>{children}</div>
            <div className={styles.name}>{fullName}</div>
            <div className={styles.jobTitle}>{jobTitle}</div>
        </div>
    </>
}