import React, {ReactNode} from 'react';
import {
    Box,
    BoxProps,
    CloseButton,
    Drawer,
    DrawerContent,
    Flex,
    FlexProps,
    Icon,
    IconButton,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import {FiHome, FiLogOut, FiMenu, FiShoppingBag,} from 'react-icons/fi';
import {IconType} from 'react-icons';
import styles from "./index.module.scss";
import {Link, useLocation} from "react-router-dom";
import {BsShop} from "react-icons/bs";
import {CgProfile} from "react-icons/cg";

interface LinkItemProps {
    name: string;
    to: string;
    target: string;
    icon: IconType | any;
    style?: any;
}

const LinkItems: Array<LinkItemProps> = [
    {name: 'Dashboard', to: '/account', target: '', icon: FiHome},
    {name: 'Profile', to: '/account/profile-settings', target: '', icon: CgProfile},
    {name: 'Orders', to: '/account/order-history', target: '', icon: FiShoppingBag},
    {name: 'Reseller', to: '/account/reseller', target: '', icon: BsShop},
    {name: 'Sign out', to: '/account/sign-out', target: '', icon: FiLogOut},
];

export default function SimpleSidebar({user, children}: { user: any, children?: ReactNode }) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
        <Box minH="100vh" w={{base: '0', lg: 60}} float={"left"}>
            <SidebarContent
                user={user}
                onClose={() => onClose}
                display={{base: 'none', lg: 'block'}}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent user={user} onClose={onClose}/>
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav display={{base: 'flex', lg: 'none'}} onOpen={onOpen}/>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    user: any,
    onClose: () => void;
}

const SidebarContent = ({user, onClose, ...rest}: SidebarProps) => {
    let isReseller = !!user?.company?.reseller;

    return (
        <Box
            w={{base: 'full', md: 60}}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <CloseButton display={{base: 'flex', md: 'none'}} onClick={onClose}/>
            </Flex>
            {LinkItems.map((link) => {
                if (!isReseller && link.name === 'Reseller') return;

                return (<NavItem key={link.name} to={link.to} target={link.target} icon={link.icon} style={link.style}>
                    {link.name}
                </NavItem>)
            })}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    to: string;
    target: string;
    children: any;
}

const NavItem = ({icon, to, target, children, ...rest}: NavItemProps) => {
    const location = useLocation();
    const isActive = to === location.pathname

    return (
        <Link to={to} target={target} style={{textDecoration: 'none'}}>
            <Flex
                className={`${styles.navItem} ${isActive ? styles.active : ''}`}
                align="center"
                py="4"
                cursor="pointer"
                {...rest}
            >
                {icon && (
                    <div><Icon
                        mr="4"
                        fontSize="1.25rem"
                        as={icon}
                    /></div>
                )}
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}

const MobileNav = ({onOpen, ...rest}: MobileProps) => {
    return (
        <Flex
            ml={{base: 0, md: 60}}
            px={{base: 4, md: 24}}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                position={"fixed"}
                left={"20px"}
                top={"30px"}
                zIndex={101}
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu/>}
            />
        </Flex>
    );
}