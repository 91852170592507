import styles from "./index.module.scss";
import {ProductType, PurchaseModal} from "../PurchaseModal";
import {
    Box,
    Button, Container,
    HStack,
    Image,
    Stack,
    Tab,
    TabIndicator,
    TabList, TabPanel, TabPanels,
    Tabs,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {Link, useNavigate} from "react-router-dom";
import p78CloudWhite from "../../assets/p78_cloud_white.svg";
import p78OnPremWhite from "../../assets/p78_on_prem_white.svg";
import React from "react";

export default function ProductSection({tabClassName, callToAction, backgroundImage, slogan, shortDescription, logo, lowest, description, process, reviews, productType}: { tabClassName: string, callToAction: string, lowest: string, productType: ProductType, description: any, process: any, reviews: any, backgroundImage: any, shortDescription: string, slogan: string, logo: any }) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const navigate = useNavigate();
    lowest = lowest || "$ XXXXX"

    let primaryColor = productType === ProductType.CLOUD ? "var(--primary-cloud-color)" : "var(--primary-onprem-color)"
    let secondaryColor = productType === ProductType.CLOUD ? "var(--secondary-cloud-color)" : "var(--secondary-onprem-color)"
    let capitalizedProductType = productType.charAt(0).toUpperCase() + productType.slice(1)

    return (
        <>
            <div className={styles.productSection}>
                <Box textAlign={"center"} maxW={"100vw"} position={"relative"} py={36}>
                    <Stack maxW={"70%"} minH={"70vh"} display={{
                        base: "none",
                        md: "inline-flex"
                    }} gap={24}>
                        <Image
                            width={"50%"}
                            src={logo}
                            alt={`Project78 ${capitalizedProductType}`}
                        />
                        <Image
                            width={"100%"}
                            src={backgroundImage}
                            alt={`Project78 ${capitalizedProductType} Preview`}
                        />
                    </Stack>
                    <Stack textAlign={"center"} maxW={{base: "100%", md: "30%"}} display={"inline-flex"}
                           alignItems={"center"} gap={16}
                           verticalAlign={"top"}
                    >
                        <Text fontSize={{
                            base: "1.5em",
                            md: "2em"
                        }} fontWeight={100}>
                            {shortDescription}
                        </Text>
                        <Box>
                            <Image
                                display={"inline-block"}
                                src={productType === ProductType.CLOUD ? p78CloudWhite : p78OnPremWhite}
                                alt={`Project78 ${capitalizedProductType}`}
                            />
                            <Text fontWeight={"bold"}>{slogan}</Text>
                        </Box>
                        <Image
                            width={"100%"}
                            src={backgroundImage}
                            alt={`Project78 ${capitalizedProductType} Preview`}
                            display={{
                                base: "block",
                                md: "none"
                            }}
                        />
                        <Box>
                            <b style={{margin: 0}}>From</b><br/>
                            <b style={{fontSize: "2em"}}>{lowest}</b><br/>
                            <b style={{margin: 0}}>Per Upgrade</b>
                        </Box>
                        <Text>{callToAction}</Text>
                        <HStack justifyContent={"space-around"} flexWrap={"wrap"} gap={"10px"}>
                            <PurchaseModal isOpen={isOpen} onClose={onClose} product={productType}/>
                            <Button
                                onClick={onOpen}
                                backgroundColor={primaryColor}
                                color={"white"}
                                px={16}
                                py={6}
                            >Buy Now</Button>
                            <Link to={"/#compare"} style={{margin: 0}}>
                                <Button
                                    backgroundColor={secondaryColor}
                                    color={"white"}
                                    px={16}
                                    py={6}
                                    onClick={() => navigate("/#compare")}
                                >Compare</Button>
                            </Link>
                        </HStack>
                    </Stack>
                </Box>
            </div>
            <div className={styles.productDescription}>
                <Tabs position="relative" align={"center"} defaultIndex={0}>
                    <TabList>
                        <Tab className={tabClassName}>Description</Tab>
                        <Tab className={tabClassName}>Migration Process</Tab>
                        <Tab className={tabClassName}>Reviews</Tab>
                    </TabList>
                    <TabIndicator
                        mt="-1.5px"
                        height="5px"
                        bg={primaryColor}
                        display={"none"}
                    />
                    <TabPanels>
                        <TabPanel>
                            <Container maxW='container.lg' textAlign={"left"} fontWeight={100}>
                                <Text verticalAlign={"top"} fontSize={{
                                    base: "1.1em",
                                    md: "1.2em",
                                }}>
                                    {description}
                                </Text>
                            </Container>
                        </TabPanel>
                        <TabPanel pt={"15em"} pb={"20em"} overflowX={"auto"}>
                            <HStack
                                justifyContent={"center"}
                                minW={"1100px"}
                            >
                                {process}
                            </HStack>
                        </TabPanel>
                        <TabPanel
                            display={"inline-flex"}
                            justifyContent={"space-evenly"}
                            gap={"5ch"}
                            flexWrap={"wrap"}
                            py={8}
                            textAlign={{
                                base: "center",
                                md: "left",
                            }}
                        >
                            {reviews}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </>
    );
}
