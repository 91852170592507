import './index.scss';
import {App} from "../../layouts/App";
import {
    Container,
    Text,
    Stack,
    FormControl,
    Input,
    Button,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    HStack,
    Box,
    Checkbox,
    FormLabel,
    Link,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader, ModalCloseButton, ModalBody, AlertTitle, AlertIcon, AlertDescription, Alert,
} from '@chakra-ui/react';
import {
    ViewIcon,
    ViewOffIcon
} from '@chakra-ui/icons';
import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {UserService} from "../../services/user";
import {useForm} from "react-hook-form";
import CustomFormControl, {CustomFormControlSelect} from "../../modules/CustomFormControl";
import {countries} from "countries-list";
import {CompanyDetailsForm} from "../../modules/CompanyDetailsForm";
import {PrivacyPolicyModal} from "../../modules/PrivacyPolicyModal";
import {CompanyService} from "../../services/company";

export default function AccountSignUp() {
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit, watch} = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [invitedCompanyName, setInvitedCompanyName] = useState("");
    const [inviteValid, setInviteValid] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const {isOpen, onOpen, onClose} = useDisclosure()

    // get email from query
    const query = new URLSearchParams(location.search);
    const email = query.get('email');
    const inviteToken = query.get('invite');

    const handlePasswordVisibility = _ => setShowPassword(!showPassword);
    const onSubmit = async values => {
        try {
            await UserService.signUp(values)
            // Try to grab the previous location from location state, else goto /account
            let to = location.state ? location.state.from.pathname : '/account'
            navigate(to, {replace: true});
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }

        setShowPassword(false);
    }

    useEffect(() => {
        // Get company by invite token
        if (inviteToken) {
            CompanyService.getCompanyByInviteToken(inviteToken, email)
                .then(({valid, name}) => {
                    setInviteValid(valid)
                    if (valid) {
                        setInvitedCompanyName(name)
                    }
                })
        }
    }, [])

    return (
        <App>
            <Container style={{paddingTop: "3%"}}>
                <Text as={"b"} fontSize='2xl'>Create an account</Text>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={4} py={"15%"} px={{
                        base: "5%",
                        md: "15%"
                    }}>
                        {inviteToken && inviteValid && <input
                            type="hidden"
                            {...register(`inviteToken`)}
                            defaultValue={inviteToken}
                        />}
                        {inviteToken && !inviteValid && <>
                            <Alert status='error'>
                                <Box my={4}>
                                    <AlertTitle
                                        fontSize='lg'
                                    >
                                        <AlertIcon
                                            className={"alertIcon"}
                                            display={"inline-block"}
                                            verticalAlign={"middle"}
                                        /> Invitation Invalid
                                    </AlertTitle>
                                    <AlertDescription maxWidth='sm' pb={4}>
                                        The invitation you are trying to use is invalid or has expired.
                                        Please contact your administrator for a new invitation.
                                    </AlertDescription>
                                </Box>
                            </Alert>
                        </>}
                        {inviteValid && <>
                            <Stack spacing={6}>
                                {inviteToken && inviteValid && <CustomFormControl
                                    fieldKey={"__company"}
                                    label={"Company"}
                                    errors={errors}
                                    register={register}
                                    defaultValue={invitedCompanyName}
                                    isReadOnly={true}
                                />}
                                <HStack>
                                    <CustomFormControl
                                        fieldKey={"firstName"}
                                        label={"First Name"}
                                        errors={errors}
                                        register={register}
                                        autoComplete={"given-name"}
                                        required={true}
                                        autoFocus={true}
                                    />
                                    <CustomFormControl
                                        fieldKey={"lastName"}
                                        label={"Last Name"}
                                        errors={errors}
                                        register={register}
                                        autoComplete={"family-name"}
                                        required={true}
                                    />
                                </HStack>
                                <CustomFormControl
                                    fieldKey={"email"}
                                    label={"Email"}
                                    errors={errors}
                                    register={register}
                                    required={true}
                                    type={"email"}
                                    defaultValue={email}
                                    isReadOnly={!!email}
                                />
                                <FormControl isInvalid={errors.confirmPassword as unknown as boolean}
                                             variant={"floating"}>
                                    <HStack>
                                        <Box>
                                            <InputGroup>
                                                <Input
                                                    {...register("password", {required: true})}
                                                    type={showPassword ? 'text' : 'password'}
                                                    height={"3rem"}
                                                />
                                                <FormLabel>Password</FormLabel>
                                                <InputRightElement width="3rem" paddingTop={"0.5rem"}>
                                                    <Button
                                                        backgroundColor={"transparent"}
                                                        size='sm'
                                                        tabIndex={-1}
                                                        onClick={handlePasswordVisibility}
                                                    >
                                                        {!showPassword ? <ViewOffIcon/> : <ViewIcon/>}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        </Box>
                                        <Box>
                                            <InputGroup>
                                                <Input
                                                    {...register("confirmPassword", {
                                                        required: true,
                                                        validate: (val: string) => {
                                                            if (watch('password') !== val) {
                                                                return "Passwords do no match";
                                                            }
                                                        },
                                                    })}
                                                    type={showPassword ? 'text' : 'password'}
                                                    height={"3rem"}
                                                />
                                                <FormLabel>Confirm</FormLabel>
                                                <InputRightElement width="3rem" paddingTop={"0.5rem"}>
                                                    <Button
                                                        backgroundColor={"transparent"}
                                                        tabIndex={-1}
                                                        size='sm'
                                                        onClick={handlePasswordVisibility}
                                                    >
                                                        {!showPassword ? <ViewOffIcon/> : <ViewIcon/>}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        </Box>
                                    </HStack>
                                    <FormErrorMessage>{errors.confirmPassword?.message as unknown as string}</FormErrorMessage>
                                </FormControl>

                                {!(inviteToken && inviteValid) && <><Checkbox {...register('register_company', {})}
                                                                              colorScheme="pgrey">
                                    Company details
                                </Checkbox>

                                    {watch("register_company", false) && <CompanyDetailsForm
                                        watch={watch}
                                        register={register}
                                        errors={errors}
                                    />}
                                </>}

                                <Checkbox {...register('email_marketing', {})} colorScheme="pgrey" defaultChecked>Sign
                                    me up
                                    to receive exclusive updates and offers!</Checkbox>
                                <Checkbox marginTop={"0.5em !important"} {...register('eula', {})} colorScheme="pgrey"
                                          required={true}>
                                    I agree to Linux Belgium's <Box
                                    color={"teal.400"}
                                    className={"privacyPolicy"}
                                    as={"a"}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onOpen()
                                    }}
                                >
                                    Privacy and Cookie Policy
                                </Box>
                                </Checkbox>
                                <PrivacyPolicyModal
                                    isOpen={isOpen}
                                    onClose={onClose}
                                />
                            </Stack>
                            <Stack spacing={6}>
                                <Button
                                    isLoading={isSubmitting}
                                    type="submit"
                                    width="full"
                                    mt={4}
                                    colorScheme={"black"}
                                    backgroundColor={"black"}
                                    height={"3rem"}
                                >
                                    Create an account
                                </Button>
                                <Button
                                    type="submit"
                                    width="full"
                                    mt={4}
                                    colorScheme={"black"}
                                    backgroundColor={"#8B8B8B"}
                                    onClick={() => navigate('/account/sign-in')}
                                    height={"3rem"}
                                >
                                    Sign in
                                </Button>
                            </Stack>
                        </>}
                    </Stack>
                </form>
            </Container>
        </App>
    )
        ;
}
