import styles from "./index.module.scss";
import React, {useState} from "react";
import {Button} from "@chakra-ui/react";
import {ProductType, PurchaseModal} from "../PurchaseModal";

export function PurchaseMoreButton() {
    const [isClicked, setIsClicked] = useState(false)

    return <>
        <PurchaseModal isOpen={isClicked} onClose={() => setIsClicked(false)} product={ProductType.CLOUD}/>
        <Button
            className={"primaryButton shadow"}
            onClick={() => setIsClicked(true)}
        >Purchase more upgrades</Button>
    </>
}