import styles from "./index.module.scss";
import {Card, CardBody, Box, Stack, Image, Text} from "@chakra-ui/react";
import React from "react";
import {getIconByOS, getLabelByOS, TargetOsSelector} from "../TargetOsSelector";
import Checkmark from "../../assets/checkmark.svg";
import {quote} from "shell-quote";

export const serverStyle = {
    iconSize: {
        base: 6,
        md: 8,
    },
    osFontSize: {
        base: "0.9rem",
        md: "1.3rem",
    },
    fontSize: {
        base: "0.7rem",
        md: "1rem",
    },
}

export function getCommand(company, command, targetOS = null) {
    let token = company?.serverLicenseKey
    let server = company?.cloudServer

    let proxyUrl = company?.proxyUrl
    let proxyUseAuth = company?.proxyUseAuth
    let proxyUser = company?.proxyUser
    let proxyPass = company?.proxyPass

    return _getCommand(token, server, command, targetOS, proxyUrl, proxyUseAuth, proxyUser, proxyPass)
}

function _getCommand(serverLicense, updateServerHostname, command, targetOS = null, proxyUrl = null, proxyUseAuth = null, proxyUser = null, proxyPass = null, insecure = false) {
    let curlArray = ["curl"]

    if (insecure) {
        curlArray.push("-k")
    }

    if (proxyUrl) {
        if (proxyUseAuth && proxyUser) {
            curlArray.push("--proxy-anyauth")
            curlArray.push("--proxy-user")

            let auth = proxyUser
            if (proxyPass) {
                auth += `:${proxyPass}`
            }

            curlArray.push(auth)
        }

        curlArray.push("-x")
        curlArray.push(proxyUrl)
    }

    curlArray.push("-fs")
    curlArray.push(`https://${updateServerHostname}/p78.php?token=${serverLicense}&action=${command}`)

    let curlCommand = quote(curlArray)
    let execCommand = targetOS ? quote(["/root/project78/p78", updateServerHostname, command, targetOS]) : ""

    return `sudo -s -- sh -c '[ ! -f /root/project78/p78 ] && { ${curlCommand} | tar -xzf - -C /root || echo "Server not reachable"; }; ${execCommand}'`
}

export function getOnPremCommand(company) {
    let serverLicense = company?.serverLicenseKey
    let updateServerHostname = company?.cloudServer

    let proxyUrl = company?.proxyUrl
    let proxyUseAuth = company?.proxyUseAuth
    let proxyUser = company?.proxyUser
    let proxyPass = company?.proxyPass

    let curlArray = ["curl"]

    if (proxyUrl) {
        if (proxyUseAuth && proxyUser) {
            curlArray.push("--proxy-anyauth")
            curlArray.push("--proxy-user")

            let auth = proxyUser
            if (proxyPass) {
                auth += `:${proxyPass}`
            }

            curlArray.push(auth)
        }

        curlArray.push("-x")
        curlArray.push(proxyUrl)
    }

    curlArray.push("-fs")
    curlArray.push(`https://${updateServerHostname}/p78.php?token=${serverLicense}&action=onprem`)

    let curlCommand = quote(curlArray)

    return `sudo -s -- sh -c '[ ! -f /opt/project78-server/p78server ] && { ${curlCommand} | tar -xzf - -C /opt || echo "Server not reachable"; }; sudo chown -R $USER:$USER /opt/project78-server'`
}

export function SimpleServer({
                                 children,
                                 title,
                                 hostname,
                                 image,
                                 className,
                                 targetOS,
                                 setTargetOS = (_) => {
                                 },
                                 targetOsSubTest = <>&nbsp;</>,
                                 isOsReadOnly = false,
                                 hideTargetOs = false,
                                 progressValue = 0,
                                 progressColor = "transparent",
                                 showCheckmark = false,
                                 middleElement = <></>
                             }: any) {
    let progress = Number(progressValue)

    return <Card className={className}>
        <CardBody position={"relative"} minHeight={"9rem"} padding={"1em"} paddingY={"0em"}>
            <Box
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    borderRadius: "9px",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: showCheckmark ? `url(${Checkmark})` : '',
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundColor: progressColor,
                    transition: "all 5s cubic-bezier(0.33, 0, 0.67, 1) 0s",
                    clipPath: `polygon(${progress}% 0, ${progress + 5}% 50%, ${progress}% 100%, 0 100%, 0 0)`
                }}
            ></Box>
            <Stack position={"relative"} height={"100%"} display={"flex"} justifyContent={"space-evenly"}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box>
                        <Box>
                            {image &&
                                <Image
                                    src={image}
                                    mr={2}
                                    h={serverStyle.iconSize}
                                    w={serverStyle.iconSize}
                                    display={"inline"}
                                    verticalAlign={"bottom"}
                                />}
                            <Text as={"b"} fontSize={serverStyle.osFontSize}>
                                {title}
                            </Text>
                        </Box>
                        <Text fontSize={serverStyle.fontSize}>
                            {hostname}
                        </Text>
                    </Box>
                    {middleElement}
                    {targetOS && <Box display={"flex"} gap={"20px"}>
                        {!isOsReadOnly && !hideTargetOs && <TargetOsSelector setTargetOSValue={setTargetOS} targetOSValue={targetOS}
                                                            isReadOnly={isOsReadOnly}/>}
                        {isOsReadOnly && !hideTargetOs && <Box textAlign={"right"}>
                            <Box>
                                <Image
                                    src={getIconByOS(targetOS, true)}
                                    mr={2}
                                    h={serverStyle.iconSize}
                                    w={serverStyle.iconSize}
                                    display={"inline"}
                                    verticalAlign={"bottom"}
                                />
                                <Text as={"b"} fontSize={serverStyle.osFontSize}>
                                    {getLabelByOS(targetOS, true)}
                                </Text>
                            </Box>
                            <Text fontSize={serverStyle.fontSize}>
                                {targetOsSubTest}
                            </Text>
                        </Box>}
                    </Box>}
                </Box>
                {false && <hr style={{margin: 0}}/>}
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} style={{margin: 0}}>
                    {children}
                </Box>
            </Stack>
        </CardBody>
    </Card>
}