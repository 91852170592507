import './index.scss';
import styles from "../Home/index.module.scss";
import {AppSignedIn} from "../../layouts/AppSignedIn";
import {
    Alert,
    AlertIcon, Box,
    Button,
    Card,
    CardBody, CardHeader, IconButton, SkeletonText,
    Stack,
    Table,
    TableContainer,
    Tbody, Td, Text,
    Th,
    Thead,
    Tr, useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {UserService} from "../../services/user";
import {useForm} from "react-hook-form";
import CustomFormControl from "../../modules/CustomFormControl";
import {ResellerService} from "../../services/reseller";
import {EntitlementsCardBanner} from "../../modules/EntitlementsCardBanner";
import {CookieUtil} from "../../utils/cookie";
import {FaCog, FaRegEye} from "react-icons/fa";
import {FiDownload, FiRefreshCw} from "react-icons/fi";
import ResellerLicenseRow from "../../modules/ResellerLicenseRow";

export default function Reseller() {
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [resellerLicenses, setResellerLicenses] = useState([])
    const [user, setUser] = useState({} as any)
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit, reset} = useForm();

    const onSubmit = async values => {
        try {
            await ResellerService.createLicense(values)
            reset()
            getResellerLicenses().then()
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    async function getResellerLicenses() {
        const response = await ResellerService.listLicenses()
        setResellerLicenses(response)
        setShowSkeleton(false)
    }

    useEffect(() => {
        UserService.getMyInfo().then(setUser)
        getResellerLicenses().then()
    }, [])

    return (
        <AppSignedIn user={user} title={"Create reseller license"}>
            <Stack
                spacing={20}
                mt={10}
                mb={60}
                mr={{
                    base: "0",
                    md: "5vw",
                }}
               maxW={{
                   base: "100%",
                   md: "30vw",
               }}
               style={{
                   float: "left",
               }}
            >
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Alert status='info'>
                        <AlertIcon/>
                        Creating a license as a reseller can take up to 5 minutes and before it is available in the license list.
                        If you still don't see it after 5 minutes, feel free to contact support.
                    </Alert>
                    <CustomFormControl
                        fieldKey={"companyName"}
                        label={"Company Name"}
                        placeholder={"Linux Belgium"}
                        errors={errors}
                        register={register}
                        autoFocus={true}
                        mt={4}
                        variant={""}
                        required={true}
                    />
                    <CustomFormControl
                        fieldKey={"companyNumber"}
                        label={"Company Number"}
                        placeholder={"BE0123456789"}
                        errors={errors}
                        register={register}
                        mt={4}
                        variant={""}
                        required={true}
                    />
                    <CustomFormControl
                        fieldKey={"licenses"}
                        label={"License Amount"}
                        placeholder={"100"}
                        pattern={"[0-9]*"}
                        errors={errors}
                        register={register}
                        mt={4}
                        variant={""}
                        required={true}
                    />
                    <CustomFormControl
                        fieldKey={"ip"}
                        label={"OnPrem IP"}
                        placeholder={"10.0.20.55"}
                        pattern={"^([0-9]{1,3}\.){3}[0-9]{1,3}$"}
                        errors={errors}
                        register={register}
                        mt={4}
                        variant={""}
                        required={true}
                    />
                    <CustomFormControl
                        fieldKey={"mac"}
                        label={"OnPrem MAC"}
                        placeholder={"00:DE:AD:BE:EF:00"}
                        pattern={"^([0-9A-Fa-f]{2}[:\\-]?){5}([0-9A-Fa-f]{2})$"}
                        errors={errors}
                        register={register}
                        mt={4}
                        variant={""}
                        required={true}
                    />
                    <CustomFormControl
                        fieldKey={"hostid"}
                        label={"OnPrem Host ID"}
                        placeholder={"a0b1c2d3e4f5a0b1c2d3e4f5a0b1c2d3"}
                        pattern={"^[a-fA-F0-9]+$"}
                        errors={errors}
                        register={register}
                        mt={4}
                        variant={""}
                        required={true}
                    />

                    <Button
                        isLoading={isSubmitting}
                        type="submit"
                        width="full"
                        mt={4}
                        colorScheme={"black"}
                        backgroundColor={"black"}
                        height={"3rem"}
                    >
                        Create License
                    </Button>
                </form>
            </Stack>
            <Stack
                spacing={20}
                mt={10}
                mb={60}
                minW={{
                    base: "100%",
                    md: "45vw",
                }}
                maxW={{
                    base: "100%",
                    md: "45vw",
                }}
                style={{
                    float: "left",
                }}
            >
                <Card className={"customCard"}>
                    <CardHeader>
                        <Text size={"2rem"} fontWeight={"bold"}>
                            Generated Licenses
                        </Text>
                    </CardHeader>
                    <CardBody minH={"50vh"}>
                        <TableContainer
                            mt={6}
                            maxH={{
                                base: "initial",
                                md: "60vh",
                            }}
                            style={{
                                overflow: "auto"
                            }}
                        >
                            <Table size='sm'>
                                <Thead>
                                    <Tr>
                                        <Th>Reference</Th>
                                        <Th>Date</Th>
                                        <Th>Description</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {showSkeleton && Array.from({length: 10}).map((_, i)=> (
                                        <Tr key={i}>
                                            <Td><SkeletonText noOfLines={1}></SkeletonText></Td>
                                            <Td><SkeletonText noOfLines={1}></SkeletonText></Td>
                                            <Td><SkeletonText noOfLines={1}></SkeletonText></Td>
                                            <Td><SkeletonText noOfLines={1}></SkeletonText></Td>
                                        </Tr>
                                    ))}
                                    {!showSkeleton && resellerLicenses.map((license, i) => <ResellerLicenseRow key={i} license={license}/>)}
                                    {!showSkeleton && resellerLicenses.length === 0 && (
                                        <Tr>
                                            <Td colSpan={5} textAlign={"center"}>No licenses found</Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </CardBody>
                </Card>
            </Stack>
        </AppSignedIn>
    );
}
