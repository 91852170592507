import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay} from "@chakra-ui/react";
import React from "react";

export function PrivacyPolicyModal({isOpen, onClose}){
    return <Modal
        isOpen={isOpen}
        onClose={onClose}
    >
        <ModalOverlay/>
        <ModalContent minW={"40vw"}>
            <ModalHeader>
                Privacy and Cookie Policy for Linux Belgium BV
                <ModalCloseButton/>
            </ModalHeader>
            <ModalBody>
                <p>Last Updated: 1/5/2023</p><br/>
                <b>Cookie Policy</b>
                <p>When you register, we collect and store your company details, username, password, and address information to create and manage your account.
                    We use your email address to send you marketing materials, only if you choose to receive them. You have the right to opt-out at any time by using the unsubscribe link in our emails or by contacting us directly.
                    We work with trusted third-party services to manage and process your email marketing preferences and data securely.
                    Payment processing is performed securely using a reliable and widely-used third-party service to ensure the safety of your financial data.
                </p>

                <br/><b>Introduction</b>
                <p>Linux Belgium BV ("we," "us," or "our") is committed to respecting and
                    protecting your privacy. This Privacy Policy explains our practices
                    regarding the collection, use, storage, and disclosure of your personal
                    information when you visit our website and interact with our services. By
                    using our website, you agree to the terms of this Privacy Policy.</p>
                <br/>

                <b>Information We Collect</b>
                <p>When you use our website, we collect the following types of information:</p>
                <p>a. Personal Information: This includes your name, company details, email
                    address, username, password, and address information when you register for
                    an account.</p>
                <p>b. Transaction Information: We collect information related to your
                    transactions, such as purchase history and payment details, for the purpose
                    of processing payments securely through our third-party payment
                    processor.</p>
                <p>c. Usage Information: We collect data on how you use our website, such as the
                    pages you visit, the time and duration of your visit, and the referring
                    URL.</p>
                <p>d. Technical Information: We collect technical data, such as your IP address,
                    device type, operating system, and browser information, to improve the
                    functionality of our website and services.</p>
                <br/>
                <b>How We Use Your Information</b>
                <p>We use the collected information to:</p>

                <p>a. Provide, maintain, and improve our services.</p>
                <p>b. Manage and secure your account.</p>
                <p>c. Process transactions and send you related information, including order
                    confirmations and invoices.</p>
                <p>d. Communicate with you, including sending you marketing materials if you
                    have opted-in to receive them.</p>
                <p>e. Monitor and analyze trends and usage to improve our website and
                    services.</p>
                <p>f. Comply with legal obligations and protect our rights.</p>

                <br/><p>Cookies and Similar Technologies</p>
                <p>We use cookies and similar technologies to personalize your experience,
                    understand how our website is used, and deliver targeted marketing. You can
                    control the use of cookies through your browser settings. By using our
                    website, you consent to our use of cookies as described in our Cookie
                    Policy.</p>

                <br/><b>Data Sharing and Third-Party Services</b>
                <p>We share your personal information with trusted third-party service providers
                    only when necessary to perform services on our behalf, such as email
                    marketing and payment processing. We require these providers to maintain the
                    confidentiality and security of your personal information and to use it only
                    for the purposes for which it was disclosed.</p>

                <br/><b>Data Retention</b>
                <p>We retain your personal information for as long as necessary to provide our
                    services, comply with legal obligations, resolve disputes, and enforce our
                    agreements.</p>

                <br/><b>Your Rights</b>
                <p>Under the GDPR, you have the right to access, rectify, erase, restrict
                    the processing of, or object to the processing of your personal
                    information. You also have the right to data portability. To exercise
                    these rights, please contact us at: info at linuxbe.com.</p>

                <br/><b>Security</b>
                <p>We implement reasonable and appropriate security measures to protect your
                    personal information from unauthorized access, disclosure, alteration,
                    or destruction. However, please note that no method of data transmission
                    or storage is 100% secure, and we cannot guarantee the absolute security
                    of your information.</p>

                <br/><b>Changes to This Privacy Policy</b>
                <p>We may update this Privacy Policy from time to time to reflect changes in
                    our practices or applicable laws. We will notify you of any material
                    changes by posting the updated policy on our website, and we encourage
                    you to review it periodically to stay informed about our privacy
                    practices.</p>

                <br/><b>Contact Us</b>
                <p>If you have any questions or concerns about this Privacy Policy or our
                    privacy practices, please contact us at: <a href={"mailto:info@linuxbe.com"}>info@linuxbe.com</a></p>
            </ModalBody>
        </ModalContent>
    </Modal>
}

