import styles from "./index.module.scss";
import {
    Box,
    Button,
    HStack,
    Input,
    Radio,
    RadioGroup,
    Text,
    useNumberInput
} from "@chakra-ui/react";
import {CookieUtil} from "../../utils/cookie";
import React, {useEffect} from "react";
import {ProductType} from "../PurchaseModal";

function getProductText(product: ProductType) {
    if(product === ProductType.CLOUD){
        return <Text color={"var(--primary-cloud-color)"} fontWeight={"600"}>
            Cloud
        </Text>
    }else if(product === ProductType.ON_PREM){
        return <Text color={"var(--primary-onprem-color)"} fontWeight={"600"}>
            OnPrem
        </Text>
    }
}

export function OrderSummary({product, unitPrice, totalPrice, qty, setQty = (val) => {}, register = null, minQty = 1}) {
    const isEditable = !!register

    let registerInputProps
    if(isEditable){
        registerInputProps = register("amount")
    }

    const step = 5;
    const min = minQty

    const {getInputProps, getIncrementButtonProps, getDecrementButtonProps} = useNumberInput({
        allowMouseWheel: true,
        step,
        defaultValue: qty,
        min,
        precision: 0,
        name: isEditable ? registerInputProps.name : "amount",
    })

    const input = getInputProps({
        onInput: (e: any) => setQty(Math.round(parseFloat(e.target.value))),
    })
    const inc = getIncrementButtonProps({
        onClick: _ => setQty(parseInt(input.value))
    })
    const dec = getDecrementButtonProps({
        onClick: _ => setQty(parseInt(input.value))
    })

    useEffect(() => {
        input.onBlur({} as any)
        setQty(Math.max(input.value, min))
    }, [minQty, product])

    return <Box px={{
        base: 0,
        md: 10
    }}>
        <HStack justifyContent={"space-between"}>
            <b>Product</b>
            <span>
                {isEditable && <HStack>
                    <RadioGroup defaultValue={product}>
                        <Radio {...register("product")} value={"cloud"} mr={"20px"}>
                            {getProductText(ProductType.CLOUD)}
                        </Radio>
                        <Radio {...register("product")} value={"on_prem"}>
                            {getProductText(ProductType.ON_PREM)}
                        </Radio>
                    </RadioGroup>
                </HStack>}
                {!isEditable && getProductText(product)}
            </span>
        </HStack>
        <hr style={{margin: "0.5em 0"}}/>
        <HStack justifyContent={"space-between"}>
            <b>Order Amount</b>
            <span>
                {isEditable && <HStack maxW='320px'>
                    <Button size={"sm"} {...dec}>-</Button>
                    <Input
                        size={"sm"}
                        borderRadius={"5px"}
                        textAlign={"center"}
                        maxW={"10ch"}
                        {...registerInputProps}
                        {...input}
                        pattern={"[0-9]*([0-9]+)?"}
                    />
                    <Button size={"sm"} {...inc}>+</Button>
                </HStack>}
                {!isEditable && <span>{qty}</span>}
            </span>
        </HStack>
        <hr style={{margin: "0.5em 0"}}/>
        <HStack justifyContent={"space-between"}>
            <b>Price Per Server</b>
            <span>{unitPrice}</span>
        </HStack>
        <hr style={{margin: "0.5em 0"}}/>
        <HStack justifyContent={"space-between"}>
            <b>
                Total excl. {CookieUtil.getCurrentLanguage() === "us" ? "sales tax" : "VAT"}
            </b>
            <span>{totalPrice}</span>
        </HStack>
        <hr style={{margin: "0.5em 0", borderTopWidth: "5px"}}/>
    </Box>
}
