import styles from "./index.module.scss";
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box,
    Button,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalHeader,
    ModalOverlay, Stack,
    Text,
    Textarea,
    useClipboard,
    useDisclosure
} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {BsClipboard, BsClipboardCheck} from "react-icons/bs";

export function ReportBox({state, server}) {
    const data = `IP: ${server.ipaddr}
Host: ${server.hostname}
Date: ${state.updatedAt}
Command: ${state.command}
Recipe: ${state.name} v${state.version} (latest: ${state.latestVersion})
State: ${state.state} (${state.action})
LOG:
${state.data}`
    const {onCopy, value, setValue, hasCopied} = useClipboard(data);
    const textRef = React.useRef<HTMLTextAreaElement>()

    useEffect(() => {
        textRef.current.scrollTop = textRef.current.scrollHeight;
    }, [textRef])

    return <Box>
        <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Button
                onClick={onCopy}
                backgroundColor={"transparent"}
                color={"black"}
                p={0}
                minW={"1.5rem"}
                h={"1.5rem"}
            >
                {hasCopied ? <BsClipboardCheck size={"2xl"}/> : <BsClipboard size={"2xl"}/>}
            </Button>
            <Text fontSize={"2xl"} fontWeight={500} display={"inline-block"}>
                {state.name}
            </Text>
        </Box>
        <time dateTime={state.updatedAt}>
            {new Date(state.updatedAt).toLocaleString()}
        </time><br/>
        <b>Status: </b><span>{state.action}</span><br/>
        <Textarea
            ref={textRef}
            isReadOnly={true}
            value={state.data}
            rows={5}
        />
    </Box>
}

export function ReportButton({serverState}: { serverState: any }) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    let {server} = serverState

    let middle = serverState.latestMiddle.cmd
    let middleStages = middle === "run" ? ["run"] : ["transfer", "run-template"]
    let installStages = [...middleStages, "postinstall"]

    return <>
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent minW={"80vw"} minH={"80vh"}>
                <ModalHeader>
                    {"Upgrade Report"}
                    <ModalCloseButton/>
                </ModalHeader>
                <ModalBody>
                    <Accordion defaultIndex={[0, 1]} allowToggle allowMultiple>
                        <AccordionItem>
                            <AccordionButton>
                                <Text fontSize={"3xl"} fontWeight={500}>
                                    Prepare
                                </Text>
                                <AccordionIcon fontSize={"3xl"} fontWeight={500} float={"right"} />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Stack gap={10}>
                                    {
                                        serverState.audit
                                            .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
                                            .map((state: any) => <ReportBox key={state.name} state={state} server={server}/>)
                                    }
                                </Stack>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton>
                                <Text fontSize={"3xl"} fontWeight={500}>
                                    Upgrade
                                </Text>
                                <AccordionIcon fontSize={"3xl"} fontWeight={500} float={"right"} />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Stack gap={10}>
                                    {
                                        installStages.map(s => {
                                            let stages = serverState[s]
                                            if(!stages){
                                                return []
                                            }

                                            return stages
                                                .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
                                                .map((state: any) => <ReportBox key={state.name} state={state} server={server}/>)
                                        }).flat()
                                    }
                                </Stack>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </ModalBody>
            </ModalContent>
        </Modal>
        <Button className={"reportButton shadow"} onClick={onOpen}>Show Report</Button>
    </>
}
