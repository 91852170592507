import './index.scss';
import {AppSignedIn} from "../../layouts/AppSignedIn";
import {
    Box,
    Button,
    ButtonGroup, HStack,
    IconButton, Skeleton, Stack,
    Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {UserService} from "../../services/user";
import {ImCheckmark, ImCross} from "react-icons/im";
import CustomFormControl, {CustomFormControlSelect} from "../../modules/CustomFormControl";
import {CompanyService} from "../../services/company";
import {countries} from "countries-list"
import {ProxyDialog} from "../../modules/ProxySettingsButton";
import {getCommand, getOnPremCommand} from "../../modules/SimpleServer";
import {CopyToClipboardField} from "../../modules/CopyToClipboardField";
import {ExternalLinkIcon} from "@chakra-ui/icons";

function SettingsBox({
                         fieldName, fieldValue, isEditing = false, setIsEditing = _ => {
    },
                         editButton = "Edit", isLoaded = true, isEditable = true, minW = "30%", children = <></>
                     }) {
    return <>
        <Box display={"inline-block"} className={"settings-box"} minW={{base: 0, sm: minW}}>
            {!isEditing && <Text display={"block"} mb={"10px"}>{fieldName}</Text>}
            {isEditing && children}
            {!isEditing && <>
                <Text display={"block"} as={"b"} fontSize='md'>
                    {isLoaded ? fieldValue : <Skeleton>{fieldValue}</Skeleton>}
                </Text>
                <Button
                    className={"small-edit-btn"}
                    variant={"unstyled"}
                    onClick={() => setIsEditing(true)}
                    visibility={isEditable ? "visible" : "hidden"}
                >
                    {editButton}
                </Button>
            </>}
        </Box>
    </>
}

function SettingsButtonGroup({isEditing, setIsEditing, isSubmitting}) {
    return <ButtonGroup variant='outline' isAttached size={"sm"} float={"right"} mt={2}>
        <IconButton
            onClick={() => setIsEditing(false)}
            isLoading={isSubmitting}
            aria-label='Cancel'
            icon={<ImCross/>}
        />
        <IconButton
            type={"submit"}
            isLoading={isSubmitting}
            aria-label='Save'
            icon={<ImCheckmark/>}
        />
    </ButtonGroup>
}

function FullNameBox({user, setUser}) {
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();
    const [isEditing, setIsEditing] = useState(false)

    const onSubmit = async values => {
        try {
            setUser(await UserService.edit(values))
            setIsEditing(false)
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return <SettingsBox
        fieldName={"Full Name"}
        fieldValue={`${user.firstName} ${user.lastName}`}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        isLoaded={!!user.firstName}
    >
        <form onSubmit={handleSubmit(onSubmit)} style={{display: "inline-block"}}>
            <CustomFormControl
                fieldKey={"firstName"}
                label={"First Name"}
                errors={errors}
                register={register}
                defaultValue={user.firstName}
                autoFocus={true}
                autoComplete="given-name"
                required={true}
                mt={4}
            />
            <CustomFormControl
                fieldKey={"lastName"}
                label={"Last Name"}
                errors={errors}
                register={register}
                defaultValue={user.lastName}
                autoComplete="family-name"
                required={true}
                mt={4}
            />
            <SettingsButtonGroup isSubmitting={isSubmitting} isEditing={isEditing} setIsEditing={setIsEditing}/>
        </form>
    </SettingsBox>
}


function PasswordBox({user, setUser}) {
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();
    const [isEditing, setIsEditing] = useState(false)

    const onSubmit = async values => {
        try {
            setUser(await UserService.edit(values))
            setIsEditing(false)
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return <SettingsBox
        fieldName={"Password"}
        fieldValue={`•••••••••••••`}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
    >
        <form onSubmit={handleSubmit(onSubmit)} style={{display: "inline-block"}}>
            <CustomFormControl
                fieldKey={"currentPassword"}
                label={"Current Password"}
                errors={errors}
                register={register}
                autoFocus={true}
                autoComplete="current-password"
                required={true}
                isPassword={true}
                mt={4}
            />
            <CustomFormControl
                fieldKey={"newPassword"}
                label={"New Password"}
                errors={errors}
                register={register}
                autoComplete="new-password"
                required={true}
                isPassword={true}
                mt={4}
            />
            <CustomFormControl
                fieldKey={"confirmPassword"}
                label={"New Password"}
                errors={errors}
                register={register}
                autoComplete="new-password"
                required={true}
                isPassword={true}
                mt={4}
            />
            <SettingsButtonGroup isSubmitting={isSubmitting} isEditing={isEditing} setIsEditing={setIsEditing}/>
        </form>
    </SettingsBox>
}

function CompanyNameBox({company, setCompany}) {
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();
    const [isEditing, setIsEditing] = useState(false)

    const onSubmit = async values => {
        try {
            setCompany(await CompanyService.edit(values))
            setIsEditing(false)
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return <SettingsBox
        fieldName={"Name"}
        fieldValue={company.companyName}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        isLoaded={!!company.companyName}
    >
        <form onSubmit={handleSubmit(onSubmit)} style={{display: "inline-block"}}>
            <CustomFormControl
                fieldKey={"companyName"}
                label={"Name"}
                errors={errors}
                register={register}
                defaultValue={company.companyName}
                autoFocus={true}
                autoComplete="organization"
                required={true}
                mt={4}
            />
            <SettingsButtonGroup isSubmitting={isSubmitting} isEditing={isEditing} setIsEditing={setIsEditing}/>
        </form>
    </SettingsBox>
}

function CompanyVatIDBox({company, setCompany}) {
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();
    const [isEditing, setIsEditing] = useState(false)

    const onSubmit = async values => {
        try {
            setCompany(await CompanyService.edit(values))
            setIsEditing(false)
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return <SettingsBox
        fieldName={"Company Number"}
        fieldValue={company.companyNumber}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        isLoaded={!!company.companyNumber}
    >
        <form onSubmit={handleSubmit(onSubmit)} style={{display: "inline-block"}}>
            <CustomFormControl
                fieldKey={"companyNumber"}
                label={"Company Number"}
                errors={errors}
                register={register}
                defaultValue={company.companyNumber}
                mt={4}
                autoFocus={true}
            />
            <SettingsButtonGroup isSubmitting={isSubmitting} isEditing={isEditing} setIsEditing={setIsEditing}/>
        </form>
    </SettingsBox>
}

function CompanyAddressBox({company, setCompany}) {
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit, watch} = useForm();
    const [isEditing, setIsEditing] = useState(false)
    let {street, street2, state, zip, city, country} = company

    const onSubmit = async values => {
        try {
            setCompany(await CompanyService.edit(values))
            setIsEditing(false)
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return <SettingsBox
        fieldName={"Address"}
        fieldValue={<>
            {street}
            <br/>
            {street2}
            <br/>
            {zip} {city}
            <br/>
            {state}
            <br/>
            {countries[country]?.name || ""}
        </>}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        isLoaded={!!street || street === ""}
    >
        <form onSubmit={handleSubmit(onSubmit)} style={{display: "inline-block"}}>
            <CustomFormControl
                fieldKey={"street"}
                label={"Street Address"}
                errors={errors}
                register={register}
                defaultValue={street}
                autoFocus={true}
                autoComplete="address-line1"
                required={true}
                mt={4}
            />
            <CustomFormControl
                fieldKey={"street2"}
                label={"Street Address 2"}
                errors={errors}
                register={register}
                autoComplete="address-line2"
                defaultValue={street2}
                mt={4}
            />
            <CustomFormControl
                fieldKey={"zip"}
                label={"ZIP Code"}
                errors={errors}
                register={register}
                defaultValue={zip}
                autoComplete="postal-code"
                required={true}
                mt={4}
            />

            <CustomFormControl
                fieldKey={"city"}
                label={"City"}
                errors={errors}
                register={register}
                defaultValue={city}
                autoComplete="home city"
                required={true}
                mt={4}
            />

            {watch("country", country) === "US" && <CustomFormControl
                fieldKey={"state"}
                label={"State"}
                errors={errors}
                register={register}
                defaultValue={state}
                autoComplete="address-level1"
                required={true}
                mt={4}
            />}

            <CustomFormControlSelect
                options={Object.entries(countries).map(([key, value]) => ({value: key, label: value.name}))}
                fieldKey={"country"}
                label={"Select country"}
                errors={errors}
                register={register}
                defaultValue={country}
                autoComplete="country"
                required={true}
                mt={4}
            />

            <SettingsButtonGroup isSubmitting={isSubmitting} isEditing={isEditing} setIsEditing={setIsEditing}/>
        </form>
    </SettingsBox>
}

function ProxyBox({company, setCompany}) {
    const {register, watch, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();
    const [isEditing, setIsEditing] = useState(false)
    let {proxyUrl, proxyUseAuth, proxyUser, proxyPass} = company
    const useAuth = watch("proxyUseAuth", proxyUseAuth)

    const onSubmit = async values => {
        try {
            setCompany(await CompanyService.edit(values))
            setIsEditing(false)
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return <>
        <SettingsBox
            fieldName={"Proxy Settings"}
            fieldValue={<>
                {proxyUrl ? proxyUrl : "No proxy configured"}
                <br/>
                {proxyUrl && proxyUseAuth ? "Authentication enabled" : "No authentication"}
            </>}
            isEditing={false}
            setIsEditing={setIsEditing}
            isLoaded={!!proxyUrl || proxyUrl === "" || proxyUrl === null}
        >
            <></>
        </SettingsBox>
        <ProxyDialog
            company={company}
            isOpen={isEditing}
            onClose={() => setIsEditing(false)}
        />

        <Box>
            <Button
                rightIcon={<ExternalLinkIcon/>}
                colorScheme='pblue'
                variant='outline'
                as={"a"}
                href={`https://${company?.cloudServer}`}
                target={"_blank"}
                isLoading={typeof company?.cloudServer === "undefined"}
            >
                OnPrem View
            </Button>
        </Box>
    </>
}

function OnPremInstallBox({company}) {
    return <>
        <SettingsBox
            fieldName={"OnPrem Installation"}
            fieldValue={<>
                <CopyToClipboardField text={getOnPremCommand(company)}/>
                <a
                    target={"_blank"}
                    href={"https://www.linuxbe.com/project78/INSTALL.html"}
                    style={{
                        textDecoration: "underline",
                        fontWeight: "normal"
                    }}
                >Install instructions</a><br/>
                <a
                    target={"_blank"}
                    href={"https://www.linuxbe.com/project78/README.html"}
                    style={{
                        textDecoration: "underline",
                        fontWeight: "normal"
                    }}
                >README</a>
            </>}
            isEditable={false}
            isLoaded={!!company.email}
        >
            <></>
        </SettingsBox>
    </>
}

export default function ProfileSettings() {
    const [user, setUser] = useState({} as any)
    const [company, setCompany] = useState({} as any)

    async function refreshUser() {
        const user = await UserService.getMyInfo()
        setUser(user)
    }

    async function refreshCompany() {
        const company = await CompanyService.getMyCompany()
        setCompany(company)
    }

    useEffect(() => {
        refreshUser().then()
        refreshCompany().then()
    }, [])

    return (
        <AppSignedIn user={user} title={"Profile Settings"}>
            <Stack spacing={20} mt={10} mb={60} maxW={"60vw"}>
                <HStack
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                    minHeight={44}
                    alignItems={"start"}
                    flexDirection={{base: "column", sm: "row"}}
                    pl={{base: 10, sm: 0}}
                >
                    <SettingsBox fieldName={"Email"} fieldValue={user.email} isLoaded={user.email} isEditable={false}/>
                    <FullNameBox user={user} setUser={setUser}/>
                    <PasswordBox user={user} setUser={setUser}/>
                </HStack>
                <Text display={"block"} as={"b"} mb={"10px"} fontSize='2xl' pt={5}>Company Settings</Text>
                <HStack
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                    minHeight={44}
                    alignItems={"start"}
                    flexDirection={{base: "column", sm: "row"}}
                    pl={{base: 10, sm: 0}}
                    pt={10}
                    style={{marginTop: 0}}
                >
                    <CompanyNameBox company={company} setCompany={setCompany}/>
                    <CompanyVatIDBox company={company} setCompany={setCompany}/>
                    <CompanyAddressBox company={company} setCompany={setCompany}/>
                </HStack>
                <Text display={"block"} as={"b"} mb={"10px"} fontSize='2xl' pt={5}>Server Settings</Text>
                <HStack
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                    minHeight={44}
                    alignItems={"start"}
                    flexDirection={{base: "column", sm: "row"}}
                    pl={{base: 10, sm: 0}}
                    pt={10}
                    style={{marginTop: 0}}
                >
                    <ProxyBox company={company} setCompany={setCompany}/>
                </HStack>
                <OnPremInstallBox company={company}/>
            </Stack>
        </AppSignedIn>
    );
}
