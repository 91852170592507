import styles from "./index.module.scss";
import {
    Box, Button, Checkbox,
    IconButton,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Stack, Text,
    useDisclosure
} from "@chakra-ui/react";
import React, {useRef} from "react";
import {useForm} from "react-hook-form";
import {CompanyService} from "../../services/company";
import {FaCog} from "react-icons/fa";
import CustomFormControl from "../CustomFormControl";

export function ProxySettingsButton({user}) {
    const {isOpen, onOpen, onClose} = useDisclosure()

    return <Box>
        <IconButton
            backgroundColor={"initial"}
            aria-label={"Proxy Settings"}
            icon={<FaCog/>}
            onClick={onOpen}
        />
        <ProxyDialog
            company={user?.company}
            isOpen={isOpen}
            onClose={onClose}
        />
    </Box>
}

export function ProxyDialog({company, isOpen, onClose}) {
    const initialRef = useRef(null)
    const {register, watch, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();
    const useAuth = watch("proxyUseAuth", company?.proxyUseAuth);

    const onSubmit = async values => {
        try{
            await CompanyService.edit(values)
            window.location.reload()
        }catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
    >
        <ModalOverlay/>
        <ModalContent minW={"40vw"}>
            <ModalHeader>
                Proxy Settings
                <ModalCloseButton/>
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <ModalBody pb={6}>
                    <Text mb='1rem'>
                        Please enter the proxy server settings so your server can reach our upgrade cloud using
                        https.
                        We support http and https proxies with and without authentication. SOCKS proxy servers are
                        not supported.
                    </Text>
                    <Text mb='1rem'>
                        🔐 Note: these settings will be applied for all upgrades in your organisation
                    </Text>


                    <Stack spacing={6}>
                        <CustomFormControl
                            fieldKey={"proxyUrl"}
                            label={"Proxy URL such as http://proxy.example.com:8080"}
                            errors={errors}
                            register={register}
                            autoFocus={true}
                            placeholder={"http://proxy.example.com:8080"}
                            mt={4}
                            pattern={"^(http|https):\\/\\/[\\w\\d\\-\\.]+(:(\\d{1,5})|)$"}
                            autoComplete={"off"}
                            variant={""}
                            ref={initialRef}
                            defaultValue={company?.proxyUrl}
                        />

                        <Checkbox
                            {...register("proxyUseAuth")}
                            defaultChecked={company?.proxyUseAuth}
                        >Authentication</Checkbox>

                        <Box display={useAuth ? "block" : "none"}>
                            <CustomFormControl
                                fieldKey={"proxyUser"}
                                label={"Username"}
                                errors={errors}
                                register={register}
                                placeholder={"proxy-user"}
                                autoComplete={"off"}
                                required={false}
                                mt={4}
                                variant={""}
                                defaultValue={company?.proxyUser}
                            />

                            <CustomFormControl
                                fieldKey={"proxyPass"}
                                label={"Password"}
                                errors={errors}
                                register={register}
                                required={false}
                                autoComplete={"off"}
                                mt={4}
                                variant={""}
                                isPassword={true}
                                defaultValue={company?.proxyPass}
                            />
                        </Box>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button isLoading={isSubmitting} type={"submit"} backgroundColor={"black"} color={"white"}
                            mr={3}>
                        Save
                    </Button>
                </ModalFooter>
            </form>
        </ModalContent>
    </Modal>
}