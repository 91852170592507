import UnitedStates from "../assets/currency/us.svg";
import Europe from "../assets/currency/eu.svg";
import Canada from "../assets/currency/ca.svg";
import UnitedKingdom from "../assets/currency/uk.svg";
import {UserService} from "../services/user";

export const supportedLanguages = [
    {
        value: "us",
        locale: "en-US",
        currency: "USD",
        label: "United States (USD)",
        icon: UnitedStates,
    },
    {
        value: "eu",
        locale: "nl-BE",
        currency: "EUR",
        label: "Europe (EUR)",
        icon: Europe,
    },
    {
        value: "ca",
        locale: "en-US",
        currency: "CAD",
        label: "Canada (CAD)",
        icon: Canada,
    },
    {
        value: "gb",
        locale: "en-GB",
        currency: "GBP",
        label: "United Kingdom (GBP)",
        icon: UnitedKingdom,
    }
]

export class CookieUtil {
    static parseCookies(){
        return document.cookie
            .split(';')
            .map(v => v.split('='))
            .reduce((acc, v) => {
                if(v.length < 2) return acc;
                acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
                return acc;
            }, {});
    }

    static getCookie(name){
        return this.parseCookies()[name]
    }

    static getCurrentCurrency(){
        let language = this.getCurrentLanguage()
        return supportedLanguages.filter(l => l.value === language)[0].currency
    }

    static async getInitialLanguage(){
        let {currency} = await fetch("/api/landing/geo").then(res => res.json())
        let currencyLang = supportedLanguages.filter(l => l.currency.toLowerCase() === currency.toLowerCase())[0]?.value
        if(currencyLang){
            return currencyLang
        }

        let [lang, region] = navigator.language.toLowerCase().split("-")
        let wantedLang;

        if(region === "us"){
            wantedLang = "us"
        }else if(region === "gb"){
            wantedLang = "gb"
        }else if(region === "ca"){
            wantedLang = "ca"
        }else{
            wantedLang = "eu"
        }

        return wantedLang
    }

    static getCurrentLanguage(){
        let cookie = this.getCookie("language")
        if(cookie){
            return cookie
        }

        this.getInitialLanguage().then(lang => {
            UserService.setLanguage(lang).then(() => window.location.reload())
        })

        return supportedLanguages[0].value
    }

    static formatCurrency(value: number, currency: string = this.getCurrentCurrency()){
        let currencyLocale = supportedLanguages.filter(l => l.currency === currency)[0].locale
        return new Intl.NumberFormat(currencyLocale, { style: 'currency', currency }).format(value).replace("US$", "$")
    }
}