import styles from "./index.module.scss";
import React, {useState} from "react";
import {Select, Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";

function recompose(obj: object, string: string) {
    let parts = string.split('.');
    if(typeof obj !== "object"){
        return null
    }
    let newObj = obj[parts[0]];
    if (parts[1]) {
        parts.splice(0, 1);
        let newString = parts.join('.');
        return recompose(newObj, newString);
    }
    return newObj;
}


export default function CustomFormControl({fieldKey, label, variant = "floating", errors, register, controlProps = {}, isPassword = false, required = false, mt = 0, ...props}) {
    const [showPassword, setShowPassword] = useState(false);
    const handlePasswordVisibility = _ => setShowPassword(!showPassword);

    let error = recompose(errors, fieldKey)
    return <FormControl isInvalid={error as unknown as boolean} variant={variant} mt={mt} {...controlProps}>
        {variant !== "floating" && <FormLabel>{label}</FormLabel>}
        <InputGroup>
            <Input {...register(fieldKey, {required})}
                   height={"3rem"}
                   {...(isPassword && {type: showPassword ? "text" : "password"})}
                   {...props}
            />
            {variant === "floating" && <FormLabel>{label}</FormLabel>}
            {isPassword && <InputRightElement width="3rem" paddingTop={"0.5rem"}>
                <Button
                    backgroundColor={"transparent"}
                    size='sm'
                    tabIndex={-1}
                    onClick={handlePasswordVisibility}
                >
                    {!showPassword ? <ViewOffIcon/> : <ViewIcon/>}
                </Button>
            </InputRightElement>}
        </InputGroup>
        <FormErrorMessage>{error?.message as unknown as string}</FormErrorMessage>
    </FormControl>
}

export function CustomFormControlSelect({fieldKey, label, errors, register, options, required = false, showLabel = false, mt = 0, ...props}) {
    return <FormControl isInvalid={errors[fieldKey] as unknown as boolean} mt={mt}>
        {showLabel && <FormLabel>{label}</FormLabel>}
        <InputGroup>
            <Select
                placeholder={label}
                height={"3rem"}
                {...props}
                {...register(fieldKey, {required})}
            >
                {options.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
            </Select>
        </InputGroup>
        <FormErrorMessage>{errors[fieldKey]?.message as unknown as string}</FormErrorMessage>
    </FormControl>
}