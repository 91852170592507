import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Td,
    Tr,
    useDisclosure
} from "@chakra-ui/react";
import {FiDownload, FiRefreshCw} from "react-icons/fi";
import {FaRegEye} from "react-icons/fa";
import React, {useState} from "react";
import {getCommand} from "../SimpleServer";
import {CopyToClipboardField} from "../CopyToClipboardField";
import {quote} from "shell-quote";
import {ResellerService} from "../../services/reseller";

export default function ResellerLicenseRow(props) {
    const [license, setLicense] = useState(props.license)
    const [isRegenerating, setIsRegenerating] = useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const alertDiscosure = useDisclosure()
    const cancelRef1 = React.useRef()

    async function regenerateLicense() {
        try {
            alertDiscosure.onClose()
            setIsRegenerating(true)
            await ResellerService.regenerateLicense(license.id)
        } catch (e) {
            console.error(e)
        }
        setIsRegenerating(false)
    }

    return (
        <Tr key={license.id}>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent maxW={{
                    base: "80vw",
                    md: "40vw",
                }}>
                    <ModalHeader>Reseller license for {license.companyName}</ModalHeader>
                    <ModalCloseButton onClick={onClose}/>
                    <ModalBody pb={6}>
                        <Box>
                            <Box fontWeight={500} as={"span"}>Reference ID:</Box> {license.id}
                        </Box>
                        <Box>
                            <Box fontWeight={500} as={"span"}>To Company Name:</Box> {license.companyName}
                        </Box>
                        <Box>
                            <Box fontWeight={500} as={"span"}>To Company ID:</Box> {license.companyNumber}
                        </Box>
                        <Box>
                            <Box fontWeight={500} as={"span"}>OnPrem IP:</Box> {license.ip}
                        </Box>
                        <Box>
                            <Box fontWeight={500} as={"span"}>OnPrem MAC:</Box> {license.mac}
                        </Box>
                        <Box>
                            <Box fontWeight={500} as={"span"}>OnPrem Host ID:</Box> {license.hostid}
                        </Box>
                        <Box>
                            <Box fontWeight={500} as={"span"}>Created:</Box> <time dateTime={license.created}>{new Date(license.created).toLocaleDateString()}</time> (by {license.resellerUserEmail})
                        </Box>
                        <Box>
                            <Box fontWeight={500} as={"span"}>Last generated:</Box> <time dateTime={license.created}>{new Date(license.lastGenerated).toLocaleDateString()}</time> (for server version {license.serverVersion} and client version {license.clientVersion})
                        </Box>
                        <br/>
                        <Box>
                            <Box fontWeight={500} as={"span"}>License:</Box> {license.license}
                        </Box>
                        <Box>
                            <Box fontWeight={500} as={"span"}>Command:</Box> {license.recentlyGenerated ? "Please allow up to 5 minutes before download is available..." : <CopyToClipboardField
                            text={quote(["curl", "-fs", license.downloadLink]) + " > " + quote([`scality-addon-offline-${license.serverVersion}-${license.clientVersion}.run`])}
                        ></CopyToClipboardField>}
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef1}
                onClose={alertDiscosure.onClose}
                isOpen={alertDiscosure.isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent minW={{
                    base: "60vw",
                    md: "20vw",
                }}>
                    <AlertDialogHeader>Regenerate download?</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        Confirm to regenerate the download file to the latest release of Project78 software
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef1} onClick={alertDiscosure.onClose}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3} onClick={regenerateLicense}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <Td fontWeight={500}>{license.id}</Td>
            <Td>
                <time dateTime={license.created}>{new Date(license.created).toLocaleDateString()}</time>
            </Td>
            <Td>{license.licenses}x licenses for {license.companyName}</Td>
            <Td>
                <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                >
                    <IconButton
                        backgroundColor={"var(--primary-onprem-color)"}
                        color={"white"}
                        aria-label={"Regenerate"}
                        icon={<FiRefreshCw/>}
                        onClick={alertDiscosure.onOpen}
                        isLoading={isRegenerating}
                    />
                    <IconButton
                        colorScheme={"black"}
                        backgroundColor={"black"}
                        aria-label={"View"}
                        icon={<FaRegEye/>}
                        onClick={onOpen}
                    />
                    <IconButton
                        isDisabled={license.recentlyGenerated}
                        isLoading={license.recentlyGenerated}
                        backgroundColor={"var(--primary-cloud-color)"}
                        color={"white"}
                        aria-label={"Download"}
                        icon={<FiDownload/>}
                        onClick={() => {
                            window.open(license.downloadLink, "_blank")
                        }}
                    />
                </Box>
            </Td>
        </Tr>
    )
}