import {Card, CardBody, HStack} from "@chakra-ui/react";
import {PurchaseMoreButton} from "../PurchaseMoreButton";
import React from "react";

export function EntitlementsCardBanner({company}){
    let totalLicensed = company?.totalLicensed || 0
    let availableLicense = company?.availableLicenses || 0

    return <Card className={"server"} gridColumn={"1 / span 2"}>
        <CardBody>
            <HStack
                justifyContent={"space-between"}
                flexDirection={{
                    base: "column",
                    lg: "row"
                }}
                gap={{
                    base: 4,
                    lg: 0
                }}
            >
                <b>{availableLicense} out of {totalLicensed} Upgrade-as-a-Service entitlements
                    remaining</b>
                <PurchaseMoreButton/>
            </HStack>
        </CardBody>
    </Card>
}