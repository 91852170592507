import styles from './index.module.scss';
import React from "react";
import p78CloudWhite from '../../assets/p78_cloud_white.svg'
import CloudBg from '../../assets/cloud_bg.png'
import {ProductType} from "../../modules/PurchaseModal";
import {Review} from "../../modules/Review";
import {ProcessStep} from "../../modules/ProcessStep";
import ProductSection from "../../modules/ProductSection";

export default function Cloud({lowest}) {
    return <ProductSection
        productType={ProductType.CLOUD}
        logo={p78CloudWhite}
        backgroundImage={CloudBg}
        lowest={lowest}
        tabClassName={styles.tab}
        slogan={"Upgrade effortlessly in the cloud"}
        shortDescription={"Single command cloud-based upgrade for RHEL7 or CentOS 7 to RHEL8 or Rocky 8 while preserving data and migrating applications."}
        callToAction={"Upgrade your system(s) now with our Upgrade-as-a-Service or see what our OnPrem version can do for larger environments"}
        description={<>
            Upgrade with ease and confidence with Project78 Cloud.<br/><br/>
            Our Cloud Upgrade-as-a-Service program ensures a smooth transition of your RHEL 7 or
            CentOS 7 server to either RHEL8 or Rocky Linux 8.<br/><br/>
            We have revolutionized the upgrade process by eliminating the need for complex
            setups. Your server, whether connected directly or via a proxy, communicates
            securely with our upgrade server on port 443 (HTTPS).<br/><br/>
            What sets us apart is our ability to preserve all data while upgrading the Operating
            System in place and migrating most configurations without any hassle.<br/><br/>
            Our goal is to keep your operations running with minimal disruptions and maximum
            reliability.<br/><br/>
            Once the upgrade is completed, we provide a comprehensive report summarizing any
            potential application migration issues or points to consider, equipping you with the
            necessary knowledge for a seamless transition.<br/><br/>
        </>}
        process={<>
            <ProcessStep
                title={"1"}
                description={"Prepare your system to verify if everything is in place for a good Operating System upgrade"}
                isCloud={true}
            />
            <ProcessStep
                title={"2"}
                description={"Pre-migration data transfers"}
                reverse={true}
                isCloud={true}
            />
            <ProcessStep
                title={"3"}
                description={"Actual migration with snapshotting and application downtime - between 60 and 5 minutes"}
                isCloud={true}
            />
            <ProcessStep
                title={"4"}
                description={"Migration report, manual re-registration with Red Hat or repository reconfiguration"}
                reverse={true}
                isCloud={true}
            />
        </>}
        reviews={<>
            <Review
                fullName={"Jasper Nuyens"}
                jobTitle={"CEO of Linux Belgium"}
            >
                <p>
                    "Project78 Cloud has successfully transformed a traditionally complex and time-consuming process into a
                    streamlined, efficient, hassle-free and secure operation. It’s a testament to the power of intelligent,
                    user-friendly technology."
                </p>
            </Review>

            <Review
                fullName={"Bart Daems"}
                jobTitle={"Creative Director at Accenture Song"}
            >
                <p>
                    "The simplicity of Project78 Cloud's service is impressive. The minimal setup requirement and the proxy
                    server connection make it extremely user-friendly."
                </p>
            </Review>
        </>}
    />
}
