import styles from "./index.module.scss";
import {
    Box,
    Button,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalHeader,
    ModalOverlay, Stack,
    Text,
    Textarea,
    useClipboard,
    useDisclosure
} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {BsClipboard, BsClipboardCheck} from "react-icons/bs";

export function ErrorBox({error, server}) {
    const data = `IP: ${server.ipaddr}
Host: ${server.hostname}
Date: ${error.updatedAt}
Command: ${error.command}
Recipe: ${error.name} v${error.version} (latest: ${error.latestVersion})
State: ${error.state} (${error.action})
LOG:
${error.data}`
    const {onCopy, value, setValue, hasCopied} = useClipboard(data);
    const textRef = React.useRef<HTMLTextAreaElement>()

    useEffect(() => {
        textRef.current.scrollTop = textRef.current.scrollHeight;
    }, [textRef])

    return <Box>
        <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Button
                onClick={onCopy}
                backgroundColor={"transparent"}
                color={"black"}
                p={0}
                minW={"1.5rem"}
                h={"1.5rem"}
            >
                {hasCopied ? <BsClipboardCheck size={"2xl"}/> : <BsClipboard size={"2xl"}/>}
            </Button>
            <Text fontSize={"2xl"} fontWeight={500} display={"inline-block"}>
                {error.name}
            </Text>
        </Box>
        <time dateTime={error.updatedAt}>
            {new Date(error.updatedAt).toLocaleString()}
        </time>
        <Textarea
            ref={textRef}
            isReadOnly={true}
            value={error.data}
            rows={5}
        />
    </Box>
}

export function ErrorButton({modalHeader, label, errors, server}: { modalHeader: string, label: string, errors: any, server: any }) {
    const {isOpen, onOpen, onClose} = useDisclosure()

    return <>
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent minW={"80vw"} minH={"80vh"}>
                <ModalHeader>
                    {modalHeader}
                    <ModalCloseButton/>
                </ModalHeader>
                <ModalBody>
                    <Stack gap={10}>
                        {errors.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()).map((error: any) =>
                            <ErrorBox key={error.name} error={error} server={server}/>)}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
        <Button className={"errorButton shadow"} onClick={onOpen}>{label}</Button>
    </>
}
