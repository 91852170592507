import React, {useEffect, useState} from "react";

const useCountdown = (targetDate, countUpwards = false) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(countUpwards ? new Date().getTime() - countDownDate : countDownDate - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            if(countUpwards){
                setCountDown(new Date().getTime() - countDownDate)
            }else{
                setCountDown(countDownDate - new Date().getTime())
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate])

    return getReturnValues(countDown);
}

function getReturnValues(totalMs): [number, number, number, number, number] {
    const days = Math.floor(totalMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (totalMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((totalMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((totalMs % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds, totalMs]
}

function formatReturnValues([days, hours, minutes, seconds, totalMs]){
    let output = ""

    if(days > 0){
        output += `${days}:`
    }

    if(days > 0 || hours > 0){
        let padding = days > 0 ? 2 : 1
        output += `${String(hours).padStart(padding, "0")}:`
    }

    if(totalMs >= 0){
        output += `${String(minutes).padStart(2, "0")}:`
    }

    if(totalMs >= 0){
        output += `${String(seconds).padStart(2, "0")}`
    }

    return output
}

export function TimeoutCountdown({startedDate, timeoutSeconds, setTotalMs = (ms) => {}}: {startedDate: string, timeoutSeconds: number, setTotalMs?: (ms) => void}){
    const [days, hours, minutes, seconds, totalMs] = useCountdown(startedDate, true)
    const countdownDone = totalMs >= timeoutSeconds * 1000

    let elapsed = formatReturnValues([days, hours, minutes, seconds, totalMs])
    let timeoutDate = formatReturnValues(getReturnValues(timeoutSeconds * 1000))

    useEffect(() => {
        setTotalMs(totalMs)
    }, [totalMs])

    return <>{!countdownDone ? <span className={"countdown"}>{elapsed}/{timeoutDate}</span> : <span className={"countdown"}>(timed out)</span>}</>
}