import './index.scss';
import {useEffect} from "react";
import {UserService} from "../../services/user";

export default function SignOut() {
    useEffect(() => {
        function afterSignOut() {
            window.location.href = "/";
        }
        UserService.signOut().then(afterSignOut).catch(afterSignOut)
    }, []);

    return <></>;
}
