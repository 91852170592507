import styles from "./index.module.scss";
import {
    Button,
    FormControl,
    FormLabel,
    ModalBody,
    ModalCloseButton, ModalContent,
    ModalFooter, ModalHeader,
    ModalOverlay,
    Modal, Text,
    useDisclosure, Input, FormErrorMessage, useToast
} from "@chakra-ui/react";
import React, {useRef} from "react";
import {useForm} from "react-hook-form";
import {UserService} from "../../services/user";
import {CustomFormControlSelect} from "../CustomFormControl";
import {countries} from "countries-list";

export function RequestCall({}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();
    const initialRef = useRef(null)
    const toast = useToast()

    const onSubmit = async values => {
        try {
            await UserService.requestCall(values)
            // show success message
        } catch (e) {
            console.error(e)
        }

        onClose()
        toast({
            title: 'Call request submitted',
            description: "We will contact you as soon as possible",
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: "top"
        })
    }

    return <>
        <Button
            onClick={onOpen}
            margin={0}
            backgroundColor={"transparent"}
            color={"#002D4B"}
            border={"2px solid #002D4B"}
            paddingX={10}
            paddingY={6}
        >
            Request call back
        </Button>
        <Modal
            initialFocusRef={initialRef}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>
                    Request a call back
                    <ModalCloseButton/>
                </ModalHeader>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody pb={6}>
                        <Text mb='1rem'>
                            Please give us a number and we'll call back as soon as possible
                        </Text>

                        <FormControl isInvalid={errors.name as unknown as boolean}>
                            <FormLabel>Name</FormLabel>
                            <Input ref={initialRef}
                                   {...register("name", {required: true})}
                                   required={true}
                                   placeholder='Name'
                            />
                            <FormErrorMessage>{errors.name?.message as unknown as string}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.email as unknown as boolean} mt={4}>
                            <FormLabel>Email</FormLabel>
                            <Input ref={initialRef}
                                   {...register("email", {required: true})}
                                   required={true}
                                   type={"email"}
                                   placeholder='Email'
                            />
                            <FormErrorMessage>{errors.email?.message as unknown as string}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.phone as unknown as boolean} mt={4}>
                            <FormLabel>Telephone</FormLabel>
                            <Input
                                {...register("phone", {required: true})}
                                required={true}
                                type={"tel"}
                                placeholder='Telephone'
                            />
                            <FormErrorMessage>{errors.phone?.message as unknown as string}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.company as unknown as boolean} mt={4}>
                            <FormLabel>Company</FormLabel>
                            <Input
                                {...register("company", {required: true})}
                                required={true}
                                placeholder='Company'
                                autoComplete="organization"
                            />
                            <FormErrorMessage>{errors.company?.message as unknown as string}</FormErrorMessage>
                        </FormControl>

                        <CustomFormControlSelect
                            mt={4}
                            options={Object.entries(countries).map(([key, value]) => ({value: key, label: value.name}))}
                            fieldKey={"country"}
                            label={"Select country"}
                            errors={errors}
                            register={register}
                            autoComplete="country"
                            required={true}
                            showLabel={true}
                        />

                        <FormControl isInvalid={errors.estimatedServers as unknown as boolean} mt={4}>
                            <FormLabel>Estimated servers to upgrade</FormLabel>
                            <Input
                                {...register("estimatedServers", {required: true})}
                                required={true}
                                type={"number"}
                                placeholder='Estimated servers to upgrade'
                            />
                            <FormErrorMessage>{errors.estimatedServers?.message as unknown as string}</FormErrorMessage>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button isLoading={isSubmitting} type={"submit"} backgroundColor={"black"} color={"white"} mr={3}>
                            Call Me
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    </>
}