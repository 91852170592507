import { HttpService } from "./httpService"

export class ResellerService extends HttpService {
    static async createLicense(values){
        return this.post("/reseller", values)
    }

    static async regenerateLicense(id: number){
        return this.post("/reseller/regenerate", {id})
    }

    static async listLicenses(){
        return this.get("/reseller")
    }
}