import styles from './index.module.scss';
import React from "react";
import p78OnPremWhite from '../../assets/p78_on_prem_white.svg'
import OnPremBg from '../../assets/onprem_bg.png'
import {ProductType} from "../../modules/PurchaseModal";
import {Review} from "../../modules/Review";
import {ProcessStep} from "../../modules/ProcessStep";
import ProductSection from "../../modules/ProductSection";

export default function OnPrem({lowest}) {
    return <ProductSection
        productType={ProductType.ON_PREM}
        logo={p78OnPremWhite}
        backgroundImage={OnPremBg}
        lowest={lowest}
        tabClassName={styles.tab}
        slogan={"Upgrade effortlessly in your environment"}
        shortDescription={"Integrate your upgrade server in your local environment with the full customisation powers."}
        callToAction={"Upgrade your system(s) now with our Upgrade-as-a-Service or see what our Cloud version can do for smaller environments"}
        description={<>
            This powerful, tailored version of our product is designed for substantial upgrade volumes, handling up to 100 servers simultaneously. With an upgrade time of approximately 30 minutes per server, you can now accomplish a whopping 1000 server upgrades within a single working day.
            <br/><br/>
            Project78 OnPrem puts you in the driver's seat, offering full customization power over the entire upgrade process. It's designed to keep the impact on your production environment to a bare minimum, helping you maintain business continuity. Following an initial rollout in non-production environments, most of our customers choose to seamlessly integrate Project78 OnPrem into their regular upgrade and maintenance cycles.
            <br/><br/>
            We understand that every environment is unique, so we offer a comprehensive 2-day course for system administrators who aim to integrate Project78 OnPrem into their specific setups. Our dedicated team is always at your disposal to ensure your transition is as smooth as possible.
            <br/><br/>
            Experience the power of mass upgrades coupled with superior customization capabilities with Project78 OnPrem - where we redefine server upgrade efficiency.
        </>}
        process={<>
            <ProcessStep
                title={"1"}
                description={"Audit run on systems to determine the preferred upgrade method"}
            />
            <ProcessStep
                title={"2"}
                description={"Pre-migration data transfers"}
                reverse={true}
            />
            <ProcessStep
                title={"3"}
                description={"Actual migration with snapshotting and application downtime - between 60 and 5 minutes"}
            />
            <ProcessStep
                title={"4"}
                description={"Post-migration validation by application owners"}
                reverse={true}
            />
            <ProcessStep
                title={"5"}
                description={"Acceptance with roll-back snapshot deletion or roll-back and recipe customizations"}
            />
        </>}
        reviews={<>
            <Review
                useRedStar={true}
                fullName={"Steve Tuyizere"}
                jobTitle={"GNU/Linux System Administrator at Federation of Notaries, Belgium"}
            >
                <p>
                    "With an impressive combination of efficiency, automation, and a deep-rooted commitment to the Free Software ethos, Project78 has revolutionized how we approached our GNU/Linux infrastructure upgrades from RHEL 7 to 8 and from CentOS 7 to Rocky Linux 8."
                </p>
            </Review>
            <Review
                useRedStar={true}
                fullName={"Kris Juchtmans"}
                jobTitle={"Project Manager, Proximus"}
            >
                <p>
                    "Project78 OnPrem has displayed its capability to reduce the strain on our teams,
                    allowing them to focus on core objectives. Its reliability in managing complex
                    server upgrades, including the successful upgrade of the SAP systems, is nothing short of impressive."
                </p>
            </Review>
        </>}
    />
}
