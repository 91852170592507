import './index.scss';
import {App} from "../../layouts/App";
import {
    Container,
    Text,
    Stack,
    FormControl,
    Input,
    Button,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    HStack,
    Box,
    Checkbox,
    FormLabel,
    Link,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader, ModalCloseButton, ModalBody, useToast, Alert, AlertIcon, AlertDescription, AlertTitle,
} from '@chakra-ui/react';
import {
    ViewIcon,
    ViewOffIcon
} from '@chakra-ui/icons';
import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {UserService} from "../../services/user";
import {useForm} from "react-hook-form";
import CustomFormControl from "../../modules/CustomFormControl";


export default function AccountSignUp() {
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit, watch} = useForm();
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(true)

    const onSubmit = async values => {
        try {
            await UserService.forgotPassword(values)
            setShowForm(false)
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return (
        <App>
            <Container style={{paddingTop: "3%"}}>
                <Text as={"b"} fontSize='2xl'>Forgot Password</Text>
                <form onSubmit={handleSubmit(onSubmit)} style={{padding: "15%"}}>
                    <Stack spacing={4}>
                        {showForm && <Stack spacing={6}>
                            <CustomFormControl
                                fieldKey={"email"}
                                label={"Email"}
                                errors={errors}
                                register={register}
                                required={true}
                                type={"email"}
                                autoFocus={true}
                            />
                        </Stack>}
                        {!showForm && <Alert status='success'>
                            <Box my={4}>
                                <AlertTitle
                                    fontSize='lg'
                                >
                                    <AlertIcon
                                        className={"alertIcon"}
                                        display={"inline-block"}
                                        verticalAlign={"middle"}
                                    /> Recovery email sent!
                                </AlertTitle>
                                <AlertDescription maxWidth='sm' pb={4}>
                                    Please check your email for a link to reset your password.
                                </AlertDescription>
                            </Box>
                        </Alert>}
                        <Stack spacing={6}>
                            {showForm && <Button
                                isLoading={isSubmitting}
                                type="submit"
                                width="full"
                                mt={4}
                                colorScheme={"black"}
                                backgroundColor={"black"}
                                height={"3rem"}
                            >
                                Send recovery email
                            </Button>}
                            <Button
                                type="submit"
                                width="full"
                                mt={4}
                                colorScheme={"black"}
                                backgroundColor={"#8B8B8B"}
                                onClick={() => navigate('/account/sign-in')}
                                height={"3rem"}
                            >
                                Sign in
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Container>
        </App>
    );
}
