import styles from "./index.module.scss";
import {Button, Flex, Input, useClipboard} from "@chakra-ui/react";
import React from "react";
import {VscCheck} from "react-icons/vsc";
import {BiCopy} from "react-icons/bi";
import CopySvg from "../../assets/copy.svg";

export function CopyToClipboardField({text}){
    const { onCopy, value, setValue, hasCopied } = useClipboard(text);

    return (
        <Flex>
            <Input
                padding={"2px"}
                pr={"0px"}
                textOverflow={"ellipsis"}
                fontFamily={"Inconsolata, monospaced"}
                fontWeight={400}
                style={{fontStretch: "75%"}}
                value={value}
                onChange={e => setValue(e.target.value)}
                mr={2}
                readOnly={true}
            />
            <Button
                className={"shadow"}
                onClick={onCopy}
                backgroundColor={"#41a0a6"}
                color={"white"}
            >
                {hasCopied ? <VscCheck size={"2em"}/> : <BiCopy size={"2em"}/>}
            </Button>
        </Flex>
    )
}