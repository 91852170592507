import './index.scss';
import {App} from "../../layouts/App";
import {
    Container,
    Text,
    Stack,
    FormControl,
    Input,
    Button,
    FormErrorMessage,
    InputGroup,
    InputRightElement, FormLabel,
} from '@chakra-ui/react';
import {
    ViewIcon,
    ViewOffIcon
} from '@chakra-ui/icons';
import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {UserService} from "../../services/user";
import CustomFormControl from "../../modules/CustomFormControl";
import {useForm} from "react-hook-form";


export default function AccountSignIn() {
    const navigate = useNavigate();
    const location = useLocation();
    const {register, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();

    const onSubmit = async values => {
        try {
            await UserService.login(values)
            // Try to grab the previous location from location state, else goto /
            let to = location.state ? location.state.from.pathname : '/account'
            navigate(to, {replace: true});
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return (
        <App>
            <Container style={{paddingTop: "3%"}}>
                <Text display={"block"} as={"b"} fontSize='2xl'>{"Sign In"}</Text>
                <form onSubmit={handleSubmit(onSubmit)} style={{padding: "15%"}}>
                    <Stack spacing={4}>
                        <Stack spacing={6}>
                            <CustomFormControl
                                fieldKey={"username"}
                                label={"Email"}
                                errors={errors}
                                register={register}
                                required={true}
                                type={"email"}
                                autoFocus={true}
                            />
                            <CustomFormControl
                                fieldKey={"password"}
                                label={"Password"}
                                errors={errors}
                                register={register}
                                required={true}
                                isPassword={true}
                            />
                        </Stack>
                        <Stack spacing={6} textAlign={"center"}>
                            <Button
                                className={"shadow"}
                                isLoading={isSubmitting}
                                type="submit"
                                width="full"
                                mt={4}
                                colorScheme={"black"}
                                backgroundColor={"black"}
                                height={"3rem"}
                            >
                                Sign In
                            </Button>
                            <a href={"/account/forgot-password"} style={{fontWeight: 600}}>Forgot password?</a>
                            <Button
                                className={"shadow"}
                                type="submit"
                                width="full"
                                mt={4}
                                colorScheme={"black"}
                                backgroundColor={"#8B8B8B"}
                                onClick={() => navigate('/account/sign-up')}
                                height={"3rem"}
                            >
                                Create an account
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Container>
        </App>
    );
}
