import styles from "./index.module.scss";
import {
    Button,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, SkeletonText, Stack, Table, Tbody, Td, Text, Th, Thead, Tr,
    useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {PaymentService} from "../../services/payment";
import {CookieUtil} from "../../utils/cookie";
import {QuoteModal} from "../QuoteModal";
import {OrderSummary} from "../OrderSummary";

function parsePricing(pricing: { [key: string]: string }, currency: string) {
    let newPricing = []

    let pricingTuples = Object.entries(pricing || {})
        .filter(([k, v]) => !["currency"].includes(k))
        .sort((a: any, b: any) => parseInt(a[0]) - parseInt(b[0]))
        .map(([qty, price]) => [parseInt(qty), parseFloat(price)])

    let lastQty = 0
    for (let [qty, price] of pricingTuples) {
        newPricing.push([
            `${++lastQty}-${qty}`,
            isNaN(price) ? null : CookieUtil.formatCurrency(price, currency)
        ])
        lastQty = qty
    }

    if (newPricing.length > 0) {
        newPricing[newPricing.length - 1][0] = `+${newPricing[newPricing.length - 1][0].split("-")[0]}`
    }

    return newPricing
}

export enum ProductType {
    CLOUD = "cloud",
    ON_PREM = "on_prem",
}

export type PricingType = {
    currency: string,
    cloud: {[key: string]: string | null},
    onPrem: {[key: string]: string | null},
    onPremMinimum: number
}

export function PurchaseModal({isOpen, onClose, product = ProductType.CLOUD}: {
    isOpen: boolean,
    onClose: () => void,
    product?: ProductType
}) {
    let defaultValue = product === ProductType.CLOUD ? 5 : 100

    const quoteDisclosure = useDisclosure()
    const [qty, setQty] = useState(defaultValue)
    const initialRef = useRef(null)
    const {register, watch, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();
    const [pricing, setPricing] = useState<PricingType>({} as any)

    const selectedProduct = watch("product", product)

    useEffect(() => {
        fetch("/api/landing/pricing")
            .then(res => res.json())
            .then(setPricing)
    }, [])

    let {currency, cloud, onPrem, onPremMinimum} = pricing
    let activePricing = (selectedProduct === "cloud" ? cloud : onPrem) || {}
    let activeFormattedPricing = parsePricing(activePricing, currency)

    const onSubmit = async values => {
        try {
            let {url} = await PaymentService.buyProduct({
                ...values,
                amount: qty
            })
            if(url){
                window.location.href = url
            }
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    function getClosestToPrice(qty): number | null {
        let cutOffs = Object.keys(activePricing).map(c => parseInt(c)).filter(c => !isNaN(c)).sort((a, b) => a - b)
        let cutOff = cutOffs.filter(c => c >= qty)[0]

        if (!cutOff) {
            cutOff = cutOffs[cutOffs.length - 1]
        }

        return parseInt(activePricing[cutOff])
    }

    let closestPrice = getClosestToPrice(qty)
    let closestPriceText = CookieUtil.formatCurrency(closestPrice, activePricing.currency)
    let totalPriceText = CookieUtil.formatCurrency(qty * closestPrice, activePricing.currency)

    return <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
    >
        <ModalOverlay/>
        <ModalContent minW={"40vw"}>
            <ModalHeader>
                Order Details
                <ModalCloseButton/>
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <ModalBody pb={6}>
                    <Stack gap={6}>
                        <OrderSummary
                            minQty={selectedProduct === "on_prem" ? onPremMinimum : 1}
                            product={selectedProduct}
                            setQty={setQty}
                            qty={qty}
                            register={register}
                            unitPrice={closestPriceText}
                            totalPrice={totalPriceText}
                        />
                        <Table
                            variant='striped'
                            maxW={"min(100%, 45rem)"}
                            margin={"0 auto !important"}
                        >
                            <Thead>
                                <Tr>
                                    <Th
                                        fontWeight={"bold"}
                                        textAlign={"center"}
                                        textTransform={"capitalize"}
                                        color={"var(--primary-font-color)"}
                                        fontSize={"1em"}
                                    >Pricing Table</Th>
                                    <Th
                                        fontWeight={"bold"}
                                        textAlign={"center"}
                                        textTransform={"capitalize"}
                                        color={"var(--primary-font-color)"}
                                        fontSize={"1em"}
                                    ># of server upgrades</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {activeFormattedPricing.length > 0 && activeFormattedPricing.filter(([qty, price]) => !!price).map(([qty, price], i) => (
                                    <Tr key={i}>
                                        <Td
                                            textAlign={"center"}
                                            py={2}
                                        >
                                            {price}
                                        </Td>
                                        <Td
                                            textAlign={"center"}
                                            py={2}
                                        >
                                            {qty}
                                        </Td>
                                    </Tr>
                                ))}
                                {activeFormattedPricing.length === 0 && Array.from({length: 3}).map((_, i) => (
                                    <Tr key={i}>
                                        <Td
                                            textAlign={"center"}
                                            py={2}
                                        >
                                            <SkeletonText noOfLines={1}/>
                                        </Td>
                                        <Td
                                            textAlign={"center"}
                                            py={2}
                                        >
                                            <SkeletonText noOfLines={1}/>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </ModalBody>

                <ModalFooter justifyContent={"space-around"}>
                    {selectedProduct === "on_prem" && <Button
                            isLoading={quoteDisclosure.isOpen}
                            onClick={quoteDisclosure.onOpen}
                            color={"white"}
                            mr={3}
                            minW={"min(50%, 15rem)"}
                            backgroundColor={"gray"}
                        >
                            Request a quote
                        </Button>}
                    <Button
                        isLoading={isSubmitting}
                        type={"submit"}
                        color={"white"}
                        mr={3}
                        minW={"min(50%, 15rem)"}
                        backgroundColor={"black"}
                    >
                        Buy Now
                    </Button>
                </ModalFooter>
            </form>
            <QuoteModal
                isOpen={quoteDisclosure.isOpen}
                onClose={(closeParent) => {
                    quoteDisclosure.onClose()
                    if(closeParent) {
                        onClose()
                    }
                }}
                product={selectedProduct}
                qty={qty}
                unitPrice={closestPriceText}
                totalPrice={totalPriceText}
            />
        </ModalContent>
    </Modal>
}