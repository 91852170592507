import React, {useState} from "react";
import {getCommand, serverStyle, SimpleServer} from "../SimpleServer";
import {
    Box, Button, Text
} from "@chakra-ui/react";
import {CopyToClipboardField} from "../CopyToClipboardField";
import {getIconByOS, getLabelByOS} from "../TargetOsSelector";
import {TimeoutCountdown} from "../TimeoutCountdown";
import {ErrorButton} from "../ErrorButton";
import {ReportButton} from "../ReportButton";

export function Server({user, serverState}: any) {
    const [backupTaken, setBackupTaken] = useState(false)

    let latestRecipe = serverState.latestBusy

    let middle = serverState.latestMiddle.cmd
    let middleStages = middle === "run" ? ["run"] : ["transfer", "run-template"]
    let installStages = [...middleStages, "postinstall"]

    let isInstallStarted = false
    let isInstallBusy = false
    let isInstallDone = false
    let isInstallError = false
    let installErrors = []

    let installValue = 0
    let installTotal = 0

    let showCheckmark = false

    for (let stage of installStages) {
        let currentStage = serverState.progress[stage]

        isInstallStarted = isInstallStarted || currentStage.started
        isInstallBusy = isInstallStarted || currentStage.busy

        if (stage === "postinstall") {
            isInstallDone = currentStage.completed
        }

        if(stage !== "postinstall"){
            installErrors = [...installErrors, ...serverState[stage].filter(r => r.action === "ERROR")]
            isInstallError = installErrors.length > 0
        }

        installValue += currentStage.done
        installTotal += currentStage.total
    }

    let isAuditStarted = serverState.progress.audit.started
    let isAuditBusy = serverState.progress.audit.busy
    let isAuditDone = serverState.progress.audit.completed
    let auditErrors = serverState.audit.filter(r => r.action === "ERROR")
    let isAuditError = auditErrors.length > 0

    let progressColor
    let progressValue = 0
    let progressTotal = 0

    if (isInstallStarted) {
        progressColor = "#29CC9A80"
        progressValue += installValue
        progressTotal += installTotal
    } else {
        progressColor = "#D4E4F2"
        progressValue += serverState.progress.audit.done
        progressTotal += serverState.progress.audit.total
    }

    let progress = (progressValue / progressTotal) * 100

    console.log({
        serverState,
        isAuditDone,
        isAuditError,
        auditErrors,
        isInstallStarted,
        isInstallBusy,
        isInstallDone,
        isInstallError,
        installErrors,
    })

    let srcOs = serverState.server.fromOs
    let targetOS = serverState.server.toOs
    let statusText = "Initialising..."
    let rightElement = <></>
    let middleElement = <></>
    let className = "server"
    let showTimeout = false

    if(!isAuditStarted){
        statusText = `Preparing...`
    } else if (isAuditBusy && !isInstallStarted) {
        statusText = `Preparing...`
        showTimeout = true
    } else if (isAuditDone && !isInstallStarted) {
        if (isAuditError) {
            statusText = "Prepare failed, try again:"
            rightElement = <CopyToClipboardField text={getCommand(user?.company, "audit", targetOS)}></CopyToClipboardField>
            middleElement = <ErrorButton
                modalHeader={"Prepare Errors"}
                label={"Prepare Error"}
                errors={auditErrors}
                server={serverState.server}
            />
        } else {
            if (!backupTaken) {
                statusText = "Prepare completed, take a backup first"
                rightElement = <Button className={"primaryButton shadow"} onClick={_ => {
                    setBackupTaken(true)
                }}>Confirm you made a backup</Button>
            } else {
                statusText = "Prepare completed, copy/paste in terminal to upgrade:"
                rightElement = <CopyToClipboardField text={getCommand(user?.company, "run-noaudit", targetOS)}/>
            }
        }
    } else if (isInstallDone) {
        if (isInstallError) {
            statusText = "Upgrade failed, issue crash report:"
            rightElement = <CopyToClipboardField text={getCommand(user?.company, "support", targetOS)}/>
            middleElement = <ErrorButton
                modalHeader={"Upgrade Errors"}
                label={"Upgrade Error"}
                errors={installErrors}
                server={serverState.server}
            />
        } else {
            statusText = "Upgrade complete!"
            showCheckmark = true
            middleElement = <ReportButton
                serverState={serverState}
            />
        }
    } else if (isInstallBusy) {
        statusText = `Upgrading...`
        showTimeout = true
    } else if (isInstallStarted) {
        statusText = "Upgrade started..."
    } else if (!isInstallStarted && !isInstallBusy && !isInstallDone) {
        // TODO: show error
        statusText = "Upgrade failed"
    }

    if(isInstallStarted || isAuditStarted || isInstallBusy || isAuditBusy){
        if(!latestRecipe.didTimeout && !isInstallError && !isAuditError){
            className += " pulse"
        }
    }

    if(showTimeout){
        if(!latestRecipe.didTimeout) {
            rightElement = <>
                <b>{latestRecipe.name}</b>
                <br/>
                <TimeoutCountdown
                    startedDate={latestRecipe.time}
                    timeoutSeconds={latestRecipe.timeoutSeconds}
                />
            </>
        }else{
            statusText = "Timed out, issue crash report:"
            rightElement = <CopyToClipboardField text={getCommand(user?.company, "support", targetOS)}/>
        }
    }

    return <SimpleServer
        user={user}
        image={getIconByOS(!showCheckmark ? srcOs : targetOS, showCheckmark)}
        title={!showCheckmark ? (serverState.server.fromOs || "Reading...") : getLabelByOS(targetOS, true)}
        hostname={serverState.server.hostname}
        targetOS={!showCheckmark && (targetOS || "placeholder")}
        isOsReadOnly={true}
        className={className}
        progressValue={!showCheckmark ? progress : 100}
        progressColor={progressColor}
        showCheckmark={showCheckmark}
        middleElement={middleElement}
    >
        <Box>
            <Text as={"b"} fontSize={serverStyle.fontSize}>{statusText}</Text>
        </Box>
        <Box flexGrow={0.5} textAlign={"right"}>
            {rightElement}
        </Box>
    </SimpleServer>
}