import { HttpService } from "./httpService"

export class CompanyService extends HttpService {
    static async edit(values){
        return this.put("/companies", values)
    }

    static async getMyCompany(){
        return this.get("/companies")
    }

    static async getCompanyByInviteToken(token, email){
        return this.get(`/companies/invite/${token}?email=${email}`)
    }
}