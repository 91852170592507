import styles from "./index.module.scss";
import {useForm} from "react-hook-form";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, useToast
} from "@chakra-ui/react";
import CustomFormControl, {CustomFormControlSelect} from "../CustomFormControl";
import {countries} from "countries-list";
import React from "react";
import {ProductType} from "../PurchaseModal";
import {OrderSummary} from "../OrderSummary";
import {CompanyDetailsForm} from "../CompanyDetailsForm";
import {CompanyService} from "../../services/company";
import {UserService} from "../../services/user";

export function QuoteModal({isOpen, onClose, totalPrice, unitPrice, qty, product = ProductType.CLOUD}) {
    const {register, watch, formState: {errors, isSubmitting}, setError, handleSubmit} = useForm();
    const toast = useToast()

    const onSubmit = async values => {
        try {
            await UserService.requestQuote(values)
            onClose(true)
            toast({
                title: 'Quote request submitted',
                description: "We will contact you as soon as possible",
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top"
            })
        } catch (e) {
            for (const [key, value] of Object.entries(e)) {
                setError(key, {message: value})
            }
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={() => onClose(false)} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        Request a quote
                        <ModalCloseButton/>
                    </ModalHeader>
                    <ModalBody>
                        <OrderSummary
                            product={product}
                            totalPrice={totalPrice}
                            unitPrice={unitPrice}
                            qty={qty}
                        />
                        <input
                            type="hidden"
                            {...register(`product`)}
                            defaultValue={product}
                        />
                        <input
                            type="hidden"
                            {...register(`qty`)}
                            defaultValue={qty}
                        />
                        <input
                            type="hidden"
                            {...register(`shownUnitPrice`)}
                            defaultValue={unitPrice}
                        />
                        <input
                            type="hidden"
                            {...register(`shownTotalPrice`)}
                            defaultValue={totalPrice}
                        />
                        <CustomFormControl
                            fieldKey={"email"}
                            label={"Email"}
                            errors={errors}
                            register={register}
                            required={true}
                            type={"email"}
                            autoFocus={true}
                            mb={4}
                        />
                        <CompanyDetailsForm
                            watch={watch}
                            register={register}
                            errors={errors}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isSubmitting}
                            type={"submit"}
                            color={"white"}
                            mr={3}
                            minW={"min(50%, 15rem)"}
                            backgroundColor={"black"}
                        >
                            Request official quote
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
