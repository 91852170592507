import './index.scss';
import {useEffect} from "react";
import {UserService} from "../../services/user";

export default function OnPremLink() {
    useEffect(() => {
        UserService.getMyInfo().then(user => {
            const host = user?.company?.cloudServer
            if(!host){
                window.close()
                return
            }

            window.location.href = `https://${host}`
        }).catch(() => {
            window.close()
        })
    }, []);

    return <></>;
}
