import { HttpService } from "./httpService"

export class UserService extends HttpService {
    static async signUp(values){
        return this.post("/users", values)
    }

    static async forgotPassword(values){
        return this.post("/users/forgotPassword", values)
    }
    static async resetPassword(values){
        return this.post("/users/resetPassword", values)
    }

    static async requestQuote(values){
        return this.post("/users/requestQuote", values)
    }

    static async edit(values){
        return this.put("/users", values)
    }

    static async setLanguage(lang){
        return this.put("/landing/language", {lang})
    }

    static async requestCall(values){
        return this.post("/users/requestCall", values)
    }

    static async getMyInfo(){
        return this.get("/users")
    }

    static async getOrders(){
        return this.get("/orders")
    }

    static async login(values){
        return this.post("/users/login", values)
    }

    static async signOut(){
        document.cookie = "";
        await this.get("/users/logout");
    }
}