import styles from "./index.module.scss";
import React, {useEffect, useState} from "react";
import {chakraComponents, Select, useChakraSelectProps} from "chakra-react-select";
import {Box, Image, useDisclosure} from "@chakra-ui/react";
import {BsGlobe2} from "react-icons/bs";
import {UserService} from "../../services/user";
import {CookieUtil, supportedLanguages} from "../../utils/cookie";

const customComponents = {
    Option: ({children, ...props}) => (
        // @ts-ignore
        <chakraComponents.Option {...props}>
            <Image src={props.data.icon} mr={2} h={5} w={5} display={"inline"} verticalAlign={"sub"}/> {children}
        </chakraComponents.Option>
    ),
    SingleValue: ({children, ...props}) => (
        // @ts-ignore
        <chakraComponents.SingleValue {...props}>
            <BsGlobe2/>
        </chakraComponents.SingleValue>
    ),
}

export function LanguageSelector() {
    let currentLang = CookieUtil.getCookie("language") || supportedLanguages[0].value
    let targetElement = supportedLanguages.filter(e => e.value === currentLang)[0]

    const [language, setLanguage] = useState(targetElement)
    const { isOpen, onOpen, onClose } = useDisclosure();

    const selectProps = useChakraSelectProps({
        onChange: setLanguage,
        options: supportedLanguages as any,
        value: language,
    })

    useEffect(() => {
        let wantedLang = language.value
        if (currentLang !== wantedLang){
            UserService.setLanguage(wantedLang).then(() => window.location.reload())
        }
        onClose()
    }, [language])

    return <>
        <Box position={"relative"} onBlur={onClose} tabIndex={-1}>
            <BsGlobe2 onClick={onOpen}/>
            <Box className={styles.select}>
                <Select
                    {...selectProps}
                    components={customComponents}
                    menuIsOpen={isOpen}
                    onMenuClose={onClose}
                />
            </Box>
        </Box>
    </>
}